import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import '../../styles/indikaattorilomake.css';
import '../../styles/MLL.css';
import Wrapper from '../../hoc/wrapper/Wrapper';
import { Row, Col, Button, Glyphicon } from 'react-bootstrap';
import { apiBaseUrl, authString } from '../../urlPaths';
import SeurantaMerkintaLista from './Seurantamerkinta/SeurantamerkintaValinta/SeurantamerkintaLista/SeurantamerkintaLista';
import SeurantamerkintaEdit from './Seurantamerkinta/SeurantamerkintaLomake/SeurantaMerkintaEdit';
import Tooltip from '../../hoc/FormComponents/Tooltip';
import Yhdistykset from './Yhdistykset';

class PiiriSivu extends Component {
  state = {
    loading: false,
    seurantamerkinnat: [],
    luokkaId: null,
    naytayhdistys: false,
    naytaperhekahvila: false,
    naytakunta: false,
    naytapiirisivu: true,
    naytalomake: false,
    piirinimi: '',
    seurantamerkintaId: 0,
    smEdit: false
  };

  componentDidMount() {
    if (this.props.piiriNimi !== null) {
      this.setState({ piirinimi: this.props.piiriNimi }); // , organisaatioId: this.props.organisaatioId })
    } else {
      this.haePiiri(this.props.piiriId);
      this.setState({
          organisaatioId: this.props.organisaatioId,
        loading: true
      });
    }
  }

  handleRaporttinakyma = (e) => {
      window.open("http://www.bit.ly/peketoiminta", "_blank");
  }

  handlenakyma = nakymanimi => {
    switch (nakymanimi) {
      case 'seurantamerkintaedit':
        this.setState({
          naytayhdistys: false,
          naytaperhekahvila: false,
          naytapiirisivu: false,
          naytakunta: false,
          naytalomake: true,
          smEdit: true
        });
        break;
      case 'yhdistys':
        this.setState({
          naytayhdistys: true,
          naytaperhekahvila: false,
          naytapiirisivu: false,
          naytakunta: false,
          naytalomake: false,
          luokkaId: 1
        });
        break;
      case 'perhekahvilat':
        this.setState({
          naytayhdistys: false,
          naytaperhekahvila: true,
          naytapiirisivu: false,
          naytakunta: false,
          naytalomake: false,
          luokkaId: 2
        });
        break;
      case 'kunnat':
          this.setState({
          naytayhdistys: false,
          naytaperhekahvila: false,
          naytakunta: true,
          naytapiirisivu: false,
          naytalomake: false,
          luokkaId: 3
        });
            break;
      case 'piirisivu':
        this.setState({
          naytapiirisivu: true,
          naytayhdistys: false,
          naytaperhekahvila: false,
          naytakunta: false,
          naytalomake: false
        });
        break;
      case 'seurantamerkintanew':
        this.setState({
          naytayhdistys: false,
          naytaperhekahvila: false,
          naytapiirisivu: false,
          naytakunta: false,
          naytalomake: true,
          seurantamerkintaId: 0,
          smEdit: false
        });
        break;
      default:
        this.setState({
          naytayhdistys: false,
          naytaperhekahvila: false,
          naytakunta: false,
          naytapiirisivu: true,
          naytalomake: false
        });
        break;
    }
  };

  haePiiri = piiriId => {
    axios
      .get(`${apiBaseUrl}HaePiiri`, {
        headers: {
          piiriId,
          Authorization: authString()
        }
      })
      .then(response => {
        this.setState({ piiriNimi: response.data.nimi, loading: false });
      })
      .catch(error => {
        console.log(error);
      });
  };

  setSeurantamerkintaId = id => {
    this.setState({ seurantamerkintaId: id });
  };

  render() {
    if (this.state.loading === true) {
      return null;
    }

    if (this.props.kayttajaTyyppi === 1 || this.props.piiriId === null) {
      return (
        <div>
          <Row style={{ paddingBottom: '30px' }}>
            <Col className='col-xs-2'>
              <Button onClick={() => this.props.goTo('Home')}>
                <Glyphicon glyph='arrow-left' />
              </Button>
            </Col>
            <Col className='col-xs-8'>
              <h2>Oikeustaso puutteellinen!</h2>
            </Col>
          </Row>
        </div>
      );
    }
    let pageContent;
    let toimintaNappi = null;
    let isDisabled = false;
    let linkkinappiluokka = 'LinkkiNappiLevea';
      if (this.props.organisaatioOverrideId > 0) {
          isDisabled = true;
          linkkinappiluokka = 'LinkkiNappiLeveaDisabled';
      } 
    let yhdistystenohjausnappi = (
          <Row>
              <Col className='col-xs-12'>
                  <Button
                    bsClass={linkkinappiluokka}
                    disabled={isDisabled}
                    onClick={() => this.handlenakyma('yhdistys')}
                  >
                      Yhdistysten ohjaus
              </Button>
              </Col>
          </Row>);
    let perhekahviloidenohjausnappi = (
          <Row>
              <Col className='col-xs-12'>
                  <Button
                    bsClass={linkkinappiluokka}
                    disabled={isDisabled}
                    onClick={() => this.handlenakyma('perhekahvilat')}
                  >
                      Perhekahviloiden ohjaus
               </Button>
              </Col>
          </Row>);
    let kuntienohjausnappi = (
          <Row>
              <Col className='col-xs-12'>
                  <Button
                      bsClass={linkkinappiluokka}
                      disabled={isDisabled}
                      onClick={() => this.handlenakyma('kunnat')}
                  >
                      Muut merkinn&auml;t
               </Button>
              </Col>
          </Row>);

    if (this.props.organisaatioOverrideId !== null) {
      toimintaNappi = (
        <Col className='col-xs-12'>
          <Button
            bsClass='LinkkiNappiLevea'
            onClick={() => this.props.goTo('Yhdistys')}
          >
           Yhdistyksen toiminta
          </Button>
        </Col>
      );
    } else {
      toimintaNappi = (
        <Col className='col-xs-12'>
          <Button bsClass='LinkkiNappiLevea' disabled>
            Yhdistyksen toiminta - valitse ensin yhdistys
          </Button>
        </Col>
      );
    }

    if (this.state.naytayhdistys === true) {
      pageContent = (
        <SeurantaMerkintaLista
              luokkaId={1}
              organisaatioId={this.props.organisaatioId}
              yhdistysNimi={this.props.yhdistysNimi}
              rajaus='yhdistykset'
              handlenakyma={this.handlenakyma}
              setSeurantamerkintaId={this.setSeurantamerkintaId}
              kayttajaId={this.props.kayttajaId}
              goTo={this.props.goTo}
              kayttajaTyyppi={this.props.kayttajaTyyppi}
        />
      );
    } else if (this.state.naytaperhekahvila === true) {
      pageContent = (
        <SeurantaMerkintaLista
          luokkaId={2}
          organisaatioId={this.props.organisaatioId}
          yhdistysNimi={this.props.yhdistysNimi}
          rajaus='perhekahvilat'
          handlenakyma={this.handlenakyma}
          setSeurantamerkintaId={this.setSeurantamerkintaId}
          kayttajaId={this.props.kayttajaId}
          goTo={this.props.goTo}
          kayttajaTyyppi={this.props.kayttajaTyyppi}
        />
        );
    } else if (this.state.naytakunta === true) {
        pageContent = (
            <SeurantaMerkintaLista
                luokkaId={3}
                organisaatioId={this.props.organisaatioId}
                yhdistysNimi={this.props.yhdistysNimi}
                rajaus='kunnat'
                handlenakyma={this.handlenakyma}
                setSeurantamerkintaId={this.setSeurantamerkintaId}
                kayttajaId={this.props.kayttajaId}
                goTo={this.props.goTo}
                kayttajaTyyppi={this.props.kayttajaTyyppi}
            />
        );
    } else if (this.state.naytapiirisivu === true) {
      pageContent = (
        <div>
          <Row style={{ paddingBottom: '30px' }}>
            <Col className='col-xs-2'>
              <Button onClick={() => this.props.goTo('Home')}>
                <Glyphicon glyph='arrow-left' />
              </Button>
            </Col>
            <Col className='col-xs-8'>
              <h2>
                Ohjaus {this.props.yhdistysNimi}
              </h2>
            </Col>
            <Col className='col-xs-2'>
              <Tooltip
                placement='left'
                title='Piirisivu'
                ohjetekstinimi='piiri'
              />
            </Col>
          </Row>
              {yhdistystenohjausnappi}
              {perhekahviloidenohjausnappi}
              {kuntienohjausnappi}
          <Row>
            <br />
          </Row>
          <Row>
            <Col className='col-xs-12'>
              <Yhdistykset
                piiriId={this.props.piiriId}
                yhdistysOverride={this.props.yhdistysOverride}
                kayttajaTyyppi={this.props.kayttajaTyyppi}
              />
            </Col>
          </Row>
          <Row>
            {toimintaNappi}
            <Col className='col-xs-12'>
              <Button onClick= {() => this.handleRaporttinakyma()} bsClass='LinkkiNappiLevea'>Ylpp&ouml;</Button>
            </Col>
          </Row>
        </div>
      );
    } else if (this.state.naytalomake === true) {
      pageContent = (
        <SeurantamerkintaEdit
          luokkaid={this.state.luokkaId}
          kayttajaid={this.props.kayttajaId}
          organisaatioid={this.props.organisaatioId}
          yhdistysnimi={this.props.yhdistysNimi}
          piiriid={this.props.piiriId}
          piirinimi={this.state.piiriNimi}
          seurantamerkintaid={this.state.seurantamerkintaId}
          handlenakyma={this.handlenakyma}
          smedit={this.state.smEdit}
          kayttajatyyppi={this.props.kayttajaTyyppi}
        />
      );
    }
    return <Wrapper>{pageContent}</Wrapper>;
  }
}

PiiriSivu.propTypes = {
  luokkaId: PropTypes.number,
  yhdistysOverride: PropTypes.func,
  kayttajaTyyppi: PropTypes.number,
  piiriNimi: PropTypes.string,
  piiriId: PropTypes.number,
  organisaatioId: PropTypes.number,
  goTo: PropTypes.func,
  organisaatioOverrideId: PropTypes.number,
  yhdistysNimi: PropTypes.string,
  kayttajaId: PropTypes.number
};

export default PiiriSivu;
