import React, { Component } from 'react';
import { Button, Row, Col, Glyphicon, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Wrapper from '../../../../hoc/wrapper/Wrapper';
import '../../../../styles/yhdistys.css';
import '../../../../styles/linkkinappi.css';
import axios from 'axios';
import { apiBaseUrl, authString } from '../../../../urlPaths';
import moment from 'moment';
import LabeledInput from '../../../../hoc/FormComponents/LabeledInput';
import Modal from '../../../../hoc/FormComponents/Modal';
import Tooltip from '../../../../hoc/FormComponents/Tooltip';

class ToimintamuodonAloitus extends React.Component {
  state = {
    toimintamuoto: {
      Id: 0,
      ToimintamuotootsikkoId: this.props.toimintamuotootsikkoId,
      Pvm_alku: moment(Date()).format('YYYY-MM-DD'),
      Onkotoimintaa: 1,
      Toimintaryhmienlukumaara: 0,
      Kokoontumisiavuodessa: 0,
      Aikuisiavuodessa: 0,
      Lapsiavuodessa: 0,
      Vapaaehtoisia: 0,
      Palkallisia: 0,
      Vapaaehtoistentuntejaperkokoontumiskertakeskimaarin: 0.0,
      Tilanne: '',
      Lisatietoa: ''
      },
    error: false,
    success: false,
    errorMessage: null,
    loading: false,
    show: false
  };

  lisaaToimintamuoto = e => {
    if (this.state.toimintamuoto.Pvm_alku !== '') {
      const kayttajaId = this.props.kayttajaId;
      const organisaatioId = this.props.organisaatioId;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: authString(),
        kayttajaId,
        organisaatioId
      };
        axios
          .post(`${apiBaseUrl}TallennaToimintaMuoto`, JSON.stringify(this.state.toimintamuoto), {
          headers
        })

        .then(response => {
          const responseData = response.data;
          console.log(responseData);
          this.setState({
            show: true,
            success: true
          });
          this.showModal();
        })
        .catch(error => {
          this.setState({
            error: true,
            errorMessage: error.message,
            show: true
          });
          this.showModal();
        });
    } else {
      this.setState({
        error: true,
        errorMessage: 'Täytä vähintään alkupäivämäärä'
      });
      this.showModal();
    }
    e.preventDefault();
  };

  handleInputChange = (updateobjectname, value) => {
    const toimintamuotoupdate = { ...this.state.toimintamuoto };
    toimintamuotoupdate[updateobjectname] = value;
    this.setState({ toimintamuoto: toimintamuotoupdate });
  };

  handleTextChange = e => {
    const target = e.target;
    const kentta = target.attributes.name.nodeValue;
    const toimintamuoto = this.state.toimintamuoto;
    toimintamuoto[kentta] = target.value;
    this.setState({ toimintamuoto });
  };

  showModal = () => {
    this.setState({ show: true });
  };

  handleHide = () => {
    if (this.state.error) {
      this.setState({
        show: false,
        error: false,
        errorMessage: null,
        success: false
      });
    } else {
      this.setState({ show: false }, () => {
        this.props.goTo('Yhdistys');
      });
    }
  };

  render() {
    let modalContent;
    if (this.state.show && this.state.error) {
      modalContent = (
        <Modal
          message={'Tallennus epäonnistui'}
          details={this.state.errorMessage}
          messageStyle={'danger'}
          title={'Toimintamuodon tallennus'}
          show={this.state.show}
          onHide={this.handleHide}
          buttonStyle={'primary'}
          buttonText={'Sulje'}
          withCancel={false}
        />
      );
    } else if (this.state.show && this.state.success) {
      modalContent = (
        <Modal
          message={'Tallennus onnistui'}
          messageStyle={'success'}
          title={'Toimintamuodon tallennus'}
          show={this.state.show}
          onHide={this.handleHide}
          buttonStyle={'primary'}
          buttonText={'Palaa etusivulle'}
        />
      );
    }

    return (
      <Wrapper>
        {this.state.show ? modalContent : null}
        <Row className='ToimintamuotoOtsikko'>
          <Col className='col-xs-2'>
            <Button onClick={() => this.props.goTo('Yhdistys')}>
              <Glyphicon glyph='arrow-left' />
            </Button>
          </Col>
          <Col className='col-xs-8'>
            <h3>{this.props.otsikko}</h3>
          </Col>
          <Col className='col-xs-2'>
            <Tooltip
              placement='left'
              title='Toimintamuodon aloitus'
              ohjetekstinimi='tmuodonaloitus'
            />
          </Col>
        </Row>
        <Form onSubmit={this.lisaaToimintamuoto}>
          <h3 className='text-center'>Aloita toimintamuoto</h3>
          <LabeledInput
            title='Aloituspäivämäärä'
            name='Pvm_alku'
            type='date'
            value={this.state.toimintamuoto.Pvm_alku}
            placeholder='Aloituspäivämäärä'
            onChange={this.handleTextChange}
            maxLength='10'
          />
                
         
          <Button bsClass='TallennaRyhma' type='submit'>
            Tallenna
          </Button>
        </Form>
      </Wrapper>
    );
  }
}
export default ToimintamuodonAloitus;

ToimintamuodonAloitus.propTypes = {
  toimintamuotoid: PropTypes.number,
  toimintamuotootsikkoId: PropTypes.number,
  otsikko: PropTypes.string,
  goTo: PropTypes.func.isRequired
};

/*
 
  <CommonLabeledNumberPicker
            title='Toimintaryhmien lkm'
            name='Toimintaryhmienlukumaara'
            placeholder='Toimintaryhmien lkm'
            value={this.state.toimintamuoto.Toimintaryhmienlukumaara}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Kokoontumisia / v'
            name='Kokoontumisiavuodessa'
            placeholder='Kokoontumisia / v'
            value={this.state.toimintamuoto.Kokoontumisiavuodessa}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Aikuisia / v'
            name='Aikuisiavuodessa'
            placeholder='Aikuisia / v'
            value={this.state.toimintamuoto.Aikuisiavuodessa}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Lapsia / v'
            name='Lapsiavuodessa'
            placeholder='Lapsia'
            value={this.state.toimintamuoto.Lapsiavuodessa}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Vapaaehtoisten lukumäärä'
            name='Vapaaehtoisia'
            placeholder='Vapaaehtoisia'
            value={this.state.toimintamuoto.Vapaaehtoisia}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Palkallisia'
            name='Palkallisia'
            placeholder='Aikuisia'
            value={this.state.toimintamuoto.Palkallisia}
            onChange={this.handleInputChange}
          />
          <LabeledDecimalPicker
            title='Vapaaehtoisten tunnit / kerta'
            name='Vapaaehtoistentuntejaperkokoontumiskertakeskimaarin'
            placeholder='Vapaaehtoisten tunnit keskimäärin'
            value={
              this.state.toimintamuoto
                .Vapaaehtoistentuntejaperkokoontumiskertakeskimaarin
            }
            onChange={this.handleInputChange}
          />
          <LabeledInput
            title='Tilanne'
            name='Tilanne'
            type='text'
            placeholder='Tilanne'
            value={this.state.toimintamuoto.Tilanne}
            onChange={this.handleTextChange}
          />
          <LabeledInput
            title='Lisätietoa'
            name='Lisatietoa'
            type='text'
            value={this.state.toimintamuoto.Lisatietoa}
            placeholder='Lisätietoa'
            onChange={this.handleTextChange}
          />
 * */