import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Glyphicon, Form } from 'react-bootstrap';
import '../../../../../styles/yhdistys.css';
import Wrapper from '../../../../../hoc/wrapper/Wrapper';
import LabeledInput from '../../../../../hoc/FormComponents/LabeledInput';
import CommonLabeledNumberPicker from '../../../../../hoc/FormComponents/Common/CommonLabeledNumberPicker';
import LabeledDecimalPicker from '../../../../../hoc/FormComponents/LabeledDecimalPicker';
import axios from 'axios';
import { apiBaseUrl, authString } from '../../../../../urlPaths';
import Modal from '../../../../../hoc/FormComponents/Modal';
import Tooltip from '../../../../../hoc/FormComponents/Tooltip';

class TapahtumanMuokkaus extends Component {
  state = {
    tapahtuma: null,
    loading: true,
    error: false,
    errorMessage: null,
    showModal: false,
    success: false
  };

  componentDidMount() {
    if (this.props.tapahtumaId !== null) {
      this.getTapahtuma(this.props.tapahtumaId);
    }
  }

  handleInputChange = (updateobjectname, value) => {
    const tapahtumaupdate = { ...this.state.tapahtuma };
    tapahtumaupdate[updateobjectname] = value;
    this.setState({ tapahtuma: tapahtumaupdate });
  };

  handleTextChange = e => {
    const target = e.target;
    const kentta = target.attributes.name.nodeValue;
    const tapahtuma = this.state.tapahtuma;
    tapahtuma[kentta] = target.value;
    this.setState({ tapahtuma });
  };

  muokkaaTapahtuma = e => {
    e.preventDefault();
    const tapahtumaData = this.state.tapahtuma;
    const headers = {
      Authorization: authString()
    };

    axios
      .post(`${apiBaseUrl}TallennaTapahtuma`, tapahtumaData, { headers })

      .then(response => {
        const newTapahtumaData = response.data;
        console.log(newTapahtumaData);
        this.setState({
          tapahtuma: newTapahtumaData,
          showModal: true,
          success: true
        });
      })
      .catch(error => {
        this.setState({
          error: true,
          errorMessage: error.message,
          showModal: true
        });
      });
    this.showModal();
  };

  getTapahtuma = tapahtumaId => {
    axios
      .get(`${apiBaseUrl}HaeTapahtuma`, {
        headers: {
          Authorization: authString(),
          id: tapahtumaId
        },
        crossdomain: true
      })
      .then(response => {
        // If request is good...
        const tapahtumaData = response.data;
        this.setState({ tapahtuma: tapahtumaData, loading: false });
      })
      .catch(error => {
        this.setState({
          error: true,
          errorMessage: error.message
        });
      });
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  handleHide = () => {
    if (this.state.error) {
      this.setState({
        showModal: false,
        error: false,
        errorMessage: null,
        success: false
      });
    } else {
      this.setState({ showModal: false }, () => {
        this.toggleGoTo(this.props.tapahtumaTaso);
      });
    }
  };

  toggleGoTo = taso => {
    this.props.handleTapahtumaTasoAndGoTo(taso, 'Tapahtuma');
  };

  render() {
    let modalContent;
    if (this.state.loading) {
      return null;
    }
    if (this.state.showModal && this.state.error) {
      modalContent = (
        <Modal
          message={'Tallennus epäonnistui'}
          details={this.state.errorMessage}
          messageStyle={'danger'}
          title={'Tilastomerkinnnän tallennus'}
          show={this.state.showModal}
          onHide={this.handleHide}
          buttonStyle={'primary'}
          buttonText={'Sulje'}
          withCancel={false}
        />
      );
    } else if (this.state.showModal && this.state.success) {
      modalContent = (
        <Modal
          message={'Tallennus onnistui'}
          messageStyle={'success'}
          title={'Tilastomerkinnnän tallennus'}
          show={this.state.showModal}
          onHide={this.handleHide}
          buttonStyle={'primary'}
          buttonText={'Palaa tilastointiin'}
        />
      );
    }
    let otsikko = null;
    if (this.props.tapahtumaTaso === 'Organisaatio') {
      otsikko = this.props.yhdistysNimi;
    } else if (this.props.tapahtumaTaso === 'Toimintamuoto') {
      otsikko = this.props.otsikko;
    } else if (this.props.tapahtumaTaso === 'Toimintaryhma') {
      otsikko = this.props.toimintaryhmaNimi;
    }
    return (
      <Wrapper>
        {this.state.showModal ? modalContent : null}
        <Row className='ToimintamuotoOtsikko'>
          <Col className='col-xs-2'>
            <Button onClick={() => this.toggleGoTo(this.props.tapahtumaTaso)}>
              <Glyphicon glyph='arrow-left' />
            </Button>
          </Col>
          <Col className='col-xs-8'>
            <h3>{otsikko}</h3>
          </Col>
          <Col className='col-xs-2'>
            <Tooltip
              placement='left'
              title='Tapahtuman muokkaus'
              ohjetekstinimi='tapahtmuokkaus'
            />
          </Col>
        </Row>
          <Form onSubmit={this.muokkaaTapahtuma}>
          <Row>
             <h3 className='text-center'>Muokkaa merkintää</h3>
          </Row>
          <LabeledInput
            title='Oma otsikko'
            name='otsikko'
            type='text'
            value={this.state.tapahtuma.otsikko}
            placeholder='Otsikko'
            onChange={this.handleTextChange}
            tooltip='tilasto_otsikko'
            maxLength='25'
          />
                   
          <LabeledInput
            title='Miltä ajalta tilastot on?Alkaen Pvm'
            type='date'
            name='alkupvm'
            value={this.state.tapahtuma.alkupvm}
            placeholder='Alkaen pvm'
            onChange={this.handleTextChange}
            tooltip='tilasto_alkupvm'
            maxLength='10'
           />
           <LabeledInput
            title='Päättyen Pvm'
            type='date'
            name='loppupvm'
            value={this.state.tapahtuma.loppupvm}
            placeholder='Päättyen pvm'
            onChange={this.handleTextChange}
            tooltip='tilasto_loppupvm'
            maxLength='10'
           />
           <CommonLabeledNumberPicker
                    title='Toimintaryhmien määrä (jos on)'
                    name='toimintaryhmia'
                    placeholder='toimintaryhmia'
                    value={this.state.tapahtuma.toimintaryhmia}
                    onChange={this.handleInputChange}
                    tooltip='tilasto_toimintaryhmienmaara'
           />
          <CommonLabeledNumberPicker
            title='Kokoontumisten / Tapahtumien määrä'
            name='tapahtumia'
            placeholder='tapahtumia'
            value={this.state.tapahtuma.tapahtumia}
            onChange={this.handleInputChange}
            tooltip='tilasto_tapahtumia'
          />
                  
          <CommonLabeledNumberPicker
            title='Aikuisten käyntikertoja / kohdattuja aikuisia'
            name='aikuisia'
            placeholder='Aikuisia'
            value={this.state.tapahtuma.aikuisia}
            onChange={this.handleInputChange}
            tooltip='tilasto_kayntikertoja'
          />
          <CommonLabeledNumberPicker
            title='Lasten käyntikertoja / Kohdattuja lapsia tai nuoria​'
            name='lapsia'
            placeholder='Lapsia'
            value={this.state.tapahtuma.lapsia}
            onChange={this.handleInputChange}
            tooltip='tilasto_lastenkayntikertoja'
          />
          <LabeledDecimalPicker
            title='Vapaaehtoisia per kerta keskimäärin'
            name='vapehtperkerta'
            placeholder='vapehtperkerta'
            value={this.state.tapahtuma.vapehtperkerta}
            onChange={this.handleInputChange}
            tooltip='tilasto_vapehtperkerta'
          /> 
         <LabeledDecimalPicker
            title='Vapaaehtoisten tunteja yhteensä'
            name='vapehttuntejayht'
            placeholder='vapehttuntejayht'
            value={this.state.tapahtuma.vapehttuntejayht}
            onChange={this.handleInputChange}
            tooltip='tilasto_vapehttuntejayht'
          />
          
   
          <LabeledInput
            title='Lisätietoa'
            name='lisatietoa'
            type='text'
            value={this.state.tapahtuma.lisatietoa}
            placeholder='Lisätietoa'
            onChange={this.handleTextChange}
            tooltip='tilasto_lisatietoa'
            maxLength='500'
          />
          <Button bsClass='TallennaRyhma' type='submit'>
            Tallenna
          </Button>
        </Form>
      </Wrapper>
    );
  }
}
TapahtumanMuokkaus.propTypes = {
  toimintaryhmaId: PropTypes.number,
  OrganisaatioId: PropTypes.number,
  toimintamuototsikotid: PropTypes.number
};

export default TapahtumanMuokkaus;
