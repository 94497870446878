import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';
// ALFAME DEVELOPMENT ASETUKSET
//export const adalConfig = {
//  tenant: 'alfame.com', // Azure Active Directory -> Properities -> Directory ID
//  clientId: '0c0392e1-98fa-4f40-a6ce-620349fcf427', //  Application id
//  endpoints: {
//    api: 'https://alfame.com/83a92f43-d7af-4c58-9378-254a04e1d791/oauth2/v2.0/token' // Url + Directory ID
//  },
//    redirectUri: 'https://seurantamll.azurewebsites.net', // https://localhost:44317 Varmista, että tähän asetettu arvo löytyy azuren redirect urleista
//  cacheLocation: 'localStorage'
//};

// TUOTANTOASETUKSET
export const adalConfig = {
    tenant: 'mll.fi', // Azure Active Directory -> Properities -> Directory ID
    clientId: '4bfd9e1a-513e-40cc-b0b1-df6de745a196', //  Application id
    endpoints: {
        api: 'https://mll.fi/c76a02d4-bb26-466d-8b8f-135ed699f407/oauth2/v2.0/token' // Url + Directory ID
    },
    redirectUri: 'https://seurantamll.azurewebsites.net', // TÄHÄN APPLIKAATION URL,
    cacheLocation: 'localStorage'
};



export const authContext = new AuthenticationContext(adalConfig);
export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
//console.log(authContext.getCachedUser());
export const getToken = () =>
  //authContext.getCachedUser().
   authContext.getCachedToken(authContext.config.clientId)
;
