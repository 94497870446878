import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  OverlayTrigger,
  Button,
  Popover,
  Glyphicon
} from 'react-bootstrap';

class Toimintamuotoinfo extends Component {
    render() {
    let ohjeteksti = this.props.ohjeteksti;
    if (typeof(this.props.ohjeteksti) == 'undefined')  {
        ohjeteksti = 'Ohjeteksti puuttuu tietokannasta';
    }
    return (
      <OverlayTrigger
        style={{ margin: '15px' }}
        trigger='click'
        placement={this.props.placement}
        overlay={
          <Popover
            id={`popover-positioned-${this.props.placement}`}
            title={this.props.title}
          >
            {ohjeteksti}
          </Popover>
        }
      >
        <Button>
          <Glyphicon glyph='info-sign' />
        </Button>
      </OverlayTrigger>
    );
  }
}

Toimintamuotoinfo.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string.isRequired,
  naytalaatikkonasivulla: PropTypes.bool,
  ohjeteksti: PropTypes.string,
};

export default Toimintamuotoinfo;
