import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../../styles/yhdistys.css';
import ToimintamuotoOtsikko from './ToimintamuotoOtsikko/ToimintamuotoOtsikko';

class ToimintamuotoLista extends Component {
    render() {
        if (this.props.toimintamuotootsikot === null) {
            return (null);
        }
    const renderOtsikot = this.props.toimintamuotootsikot.map(data => (
      <ToimintamuotoOtsikko
        key={data.id}
        toimintamuotoid={data.toimintamuoto_Id}
        data={data}
        modify={this.props.modify}
        handleDataAndGoTo={this.props.handleDataAndGoTo}
        handleData={this.props.handleData}
        showModal={this.props.showModal}
      />
    ));
    return (
      <div>
        {renderOtsikot}
      </div>
    );
  }
}
export default ToimintamuotoLista;

ToimintamuotoLista.propTypes = {
  toimintamuotootsikot: PropTypes.array,
  modify: PropTypes.bool.isRequired,
  handleDataAndGoTo: PropTypes.func.isRequired
};
