import React from 'react';
import '../../../styles/indikaattorilomake.css';
import PropTypes from 'prop-types';

const otsikko = props => (
  <div className='OhjausOtsikkoWrapperi'>
    <h2>{props.ohjausmerkinta.kuvaus}</h2>
    <h4>{props.ohjausmerkinta.tarkennukset}</h4>
  </div>
);

otsikko.propTypes = {
  ohjausmerkinta: PropTypes.object
};

export default otsikko;
