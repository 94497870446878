import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../../../../hoc/wrapper/Wrapper';
import { Row, Col, Button, Glyphicon } from 'react-bootstrap';
import ToimintaryhmaLomake from './ToimintaryhmaLomake/ToimintaryhmaLomake';

class Toimintaryhma extends Component {
  render() {
    return (
      <Wrapper>
        <Row className='ToimintamuotoOtsikko'>
          <Col className='col-xs-12'>
            <h3>{this.props.otsikko}</h3>
          </Col>
          <Col className='col-xs-4'>
            <Button onClick={() => this.props.goTo('Toimintamuoto')}>
              <Glyphicon glyph='arrow-left' />
            </Button>
          </Col>
        </Row>
        <ToimintaryhmaLomake
          organisaatioid={this.props.organisaatioid}
          toimintamuotoid={this.props.toimintamuotoid}
          kayttajaId={this.props.kayttajaId}
          goTo={this.props.goTo}
        />
      </Wrapper>
    );
  }
}

export default Toimintaryhma;

Toimintaryhma.propTypes = {
  otsikko: PropTypes.string,
  organisaatioid: PropTypes.number,
  toimintamuotoid: PropTypes.number,
  goTo: PropTypes.func.isRequired,
  kayttajaId: PropTypes.number
};
