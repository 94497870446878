import React, { Component } from 'react';
import { Layout } from './hoc/Layout/Layout';
import Home from './containers/Home/Home';

export default class App extends Component {
  displayName = App.name;

  render() {
    return (
      <Layout>
        <Home {...this.props} />
      </Layout>
    );
  }
}
