import React, { Component } from 'react';
import { Row, Col, Button, Glyphicon } from 'react-bootstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import '../../../../../styles/yhdistysvalinta.css';
import { apiBaseUrl, authString } from '../../../../../urlPaths';

class Yhdistysvalinta extends Component {
  state = {
    yhdistykset: [],
    naytettavatyhdistykset: [],
    yhdistyshaku: ''
  };

    componentDidMount() {
      // Käyttäjätyyppi = 2 Piirikäyttäjä
        if (this.props.kayttajatyyppi === 2) {
            switch (this.props.luokkaid) {
                case 3: {
                this.noudaKunnat();
                break;
                }
                default:
                this.noudaYhdistykset();
            }
      } else
      // Käyttäjätyyppi = 3 MLL / LIITTOKÄYTTÄJÄ
      if (this.props.kayttajatyyppi === 3) {
          switch (this.props.luokkaid) {
              case 3: {
                  this.noudaKaikkiKunnat();
                  break;
              }
              default:
              this.noudaKaikkiYhdistykset();
          }
         
      }
    }

  // Yhdistysten haku Piiri - käyttäjälle
  noudaYhdistykset = () => {
      const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authString(),
      };
      const piiriid = this.props.piiriid;
      axios
          .post(`${apiBaseUrl}HaePiirinOrganisaatiot`, piiriid, {
              headers
          })
          .then(response => {
              this.setState({
                  yhdistykset: response.data,
                  naytettavatyhdistykset: response.data
              });
          })
          .catch(error => {
              console.log(error);
          });
    }

    // Kuntien haku piirikäyttäjä
  noudaKunnat = () => {
      const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authString(),
      };
        const piiriid = this.props.piiriid;
        axios
            .post(`${apiBaseUrl}HaePiirinKunnat`, piiriid, {
                headers
            })
            .then(response => {
                this.setState({
                    yhdistykset: response.data,
                    naytettavatyhdistykset: response.data
                });
            })
            .catch(error => {
                console.log(error);
            });
  }

  // Kaikkien yhdistysten haku MLL - käyttäjälle
  noudaKaikkiYhdistykset = () => {
      const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authString(),
      };
      axios
          .get(`${apiBaseUrl}HaeKaikkiOrganisaatiot`, {
              headers
          })
          .then(response => {
              this.setState({
                  yhdistykset: response.data,
                  naytettavatyhdistykset: response.data
              });
          })
          .catch(error => {
              console.log(error);
          });
    }

    // Kaikkien kuntien haku
    noudaKaikkiKunnat = () => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authString(),
        };
        axios
            .get(`${apiBaseUrl}HaeKaikkiKunnat`, {
                headers
            })
            .then(response => {
                this.setState({
                    yhdistykset: response.data,
                    naytettavatyhdistykset: response.data
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

  kasitteleHaku = event => {
    this.setState({ yhdistyshaku: event.target.value });
    this.suodataYhdistykset(event.target.value);
  };

  kasitteleNapinPainallus = event => {
    const valittuId = Number(event.target.id);
    const yhdistys = this.state.yhdistykset.filter(yhd => yhd.id === valittuId);
    this.props.lisaaYhdistys(yhdistys[0]);
  };

  kasittelePoisto = event => {
    const yhdistysId = Number(event.target.id);
      this.props.poistaYhdistys(yhdistysId);
  };

  suodataYhdistykset = value => {
    //lisää tähän suodatus, joka poistaa jo valitut yhdistykset listalta!
    const suodatetut = this.state.yhdistykset.filter(y =>
      y.lyhytNimi.toLowerCase().includes(value.toLowerCase())
    );
    this.setState({ naytettavatyhdistykset: suodatetut });
  };

  render() {
    const yhdistysListaus = this.state.naytettavatyhdistykset.map(yhdistys => (
      <Row key={yhdistys.id}>
        <button
          id={yhdistys.id}
          className='YhdistysNappi'
          onClick={this.kasitteleNapinPainallus}
        >
          <h4 id={yhdistys.id}>{yhdistys.lyhytNimi}</h4>
        </button>
      </Row>
    ));

    const cvalitutYhdistykset = this.props.valitutyhdistykset.map(valittu => (
      <Row key={valittu.id}>
        <Col className='col-xs-10'>
          <h4>{valittu.lyhytNimi}</h4>
        </Col>
        <Col className='col-xs-2'>
          <Button
            id={valittu.id}
            onClick={this.kasittelePoisto}
            bsStyle='danger'
          >
            <Glyphicon id={valittu.id} bsSize='large' glyph='remove' />
          </Button>
        </Col>
      </Row>
    ));

    return (
      <div>
        {this.props.valitutyhdistykset.length > 0 ? (
          <div className='ValitutWrapperi'>
            <Button
              id='yhdistysvalinta'
              bsStyle='success'
              onClick={this.props.yhdistysvalintaClick}
            >
              Valmis
            </Button>
            <h3>Valitut yhdistykset:</h3>
            {cvalitutYhdistykset}
          </div>
        ) : null}
        <Row>
          <h4>Hae:</h4>
          <input onChange={this.kasitteleHaku} />
        </Row>
        {yhdistysListaus}
      </div>
    );
  }
}

Yhdistysvalinta.propTypes = {
  lisaaYhdistys: PropTypes.func.isRequired,
  poistaYhdistys: PropTypes.func.isRequired,
  valitutyhdistykset: PropTypes.array,
  yhdistysvalintaClick: PropTypes.func.isRequired,
  luokkaid: PropTypes.number
};

export default Yhdistysvalinta;
