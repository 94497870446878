//API URL (DEV)

export const apiBaseUrl = 'https://seurantamll.azurewebsites.net/api/MLLData/';

// PÄÄSIVU

export const homeUrl = '/';

// YHDISTYS

export const yhdistysBaseUrl = '/yhdistys';
export const toimintamuotoUrl = '/toimintamuoto';
export const toimintaryhmaUrl = '/lisaatoimintaryhma';
export const tapahtumaUrl = '/lisaatapahtuma';
export const tapahtumienSelausUrl = '/tapahtumienSelaus';
export const aloitaToimintamuotoUrl = '/aloitatoimintamuoto';

// PIIRI

export const piiriBaseUrl = '/piiri';
export const yhdistysvalintaUrl = '/yhdistysvalinta';
export const seurantamerkintavalintaUrl = '/merkintavalinta';
export const seurantamerkintaUrl = '/seurantamerkinta';

// AUTHENTICATION

export const authString = () => { return 'Bearer '.concat(localStorage.getItem('adal.idtoken')); }
