import React, { Component } from 'react';
import { FormControl, Button, Row, Col, Glyphicon, Checkbox } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { apiBaseUrl, authString } from '../../../../../urlPaths';
import Wrapper from '../../../../../hoc/wrapper/Wrapper';
import '../../../../../styles/yhdistys.css';
import axios from 'axios';
import LabeledInput from '../../../../../hoc/FormComponents/LabeledInput';
import CommonLabeledNumberPicker from '../../../../../hoc/FormComponents/Common/CommonLabeledNumberPicker';
import LabeledDecimalPicker from '../../../../../hoc/FormComponents/LabeledDecimalPicker';
import Modal from '../../../../../hoc/FormComponents/Modal';
import Tooltip from '../../../../../hoc/FormComponents/Tooltip';

class ToimintaryhmaLomake extends Component {
  state = {
    Muokkaa: false,
    toimintaryhma: null,
    toimintamuotoid: this.props.toimintamuotoid,
    loading: false,
    error: false,
    errorMessage: null,
    success: false,
    showModal: false
  };

  componentDidMount() {
    console.log('TRLOMAKE');
    if (this.props.toimintaryhmaid !== null) {
      this.haeToimintaRyhma();
      this.setState({ loading: true });
    }
  }

  handleInputChange = (updateobjectname, value) => {
    const toim_ryhma = { ...this.state.toimintaryhma };
    toim_ryhma[updateobjectname] = value;
    this.setState({ toimintaryhma: toim_ryhma });
  };

  handleTextChange = e => {
    const target = e.target;
    const kentta = target.attributes.name.nodeValue;
    const toimintaryhma = this.state.toimintaryhma;
    toimintaryhma[kentta] = target.value;
    this.setState({ toimintaryhma });
    };


  handleBoxClick = event => {
   const toim_ryhma = { ...this.state.toimintaryhma };
        if (toim_ryhma.saanayttaa == 1) {
            toim_ryhma.saanayttaa = 0;
        } else {
            toim_ryhma.saanayttaa = 1;
        }
        this.setState({ toimintaryhma: toim_ryhma });
    };


  tallenna = e => {
    e.preventDefault();
     const data = this.state.toimintaryhma;
    data.kayttajaid = this.props.kayttajaId;
    if (data.nimi !== '' && data.pvmalku) {
      axios
        .post(`${apiBaseUrl}TallennaToimintaRyhmaReduced`, data, {
          headers: {
            Authorization: authString()
          }
        })
        .then(response => {
          const toimintaryhmadata = response.data;
          this.setState({
            toimintaryhma: toimintaryhmadata,
            showModal: true,
            success: true
          });
          this.showModal();
        })
        .catch(error => {
          this.setState({
            error: true,
            errorMessage: error.message,
            showModal: true
          });
          this.showModal();
        });
    } else {
      this.setState({
        error: true,
        errorMessage: 'Täytä vähintään nimi ja aloituspäivämäärä'
      });
      this.showModal();
    }
  };

  haeToimintaRyhma = () => {
    axios
      .get(`${apiBaseUrl}HaeToimintaRyhmaReduced`, {
        headers: {
          Authorization: authString(),
          id: this.props.toimintaryhmaid
        },
        crossdomain: true
      })
      .then(response => {
        // If request is good...
        const toimintaryhmadata = response.data;
        this.setState({ toimintaryhma: toimintaryhmadata, loading: false });
      })
      .catch(error => {
        this.setState({
          error: true,
          errorMessage: error.message
        });
      });
  };

  showModal = () => {
    this.setState({ show: true });
  };

  handleHide = () => {
    if (this.state.error) {
      this.setState({
        show: false,
        error: false,
        errorMessage: null,
        success: false
      });
    } else {
      this.setState({ show: false }, () => {
        this.props.goTo('Toimintamuoto');
      });
    }
  };

  render() {
    if (this.state.toimintaryhma === null) {
      return null;
    }
    let modalContent;
    if (this.state.showmodal && this.state.error) {
      modalContent = (
        <Modal
          message={'Tallennus epäonnistui'}
          details={this.state.errorMessage}
          messageStyle={'danger'}
          title={'Toimintamuodon tallennus'}
          show={this.state.showModal}
          onHide={this.handleHide}
          buttonStyle={'primary'}
          buttonText={'Sulje'}
          withCancel={false}
        />
      );
    } else if (this.state.showModal && this.state.success) {
      modalContent = (
        <Modal
          message={'Tallennus onnistui'}
          messageStyle={'success'}
          title={'Toimintamuodon tallennus'}
          show={this.state.showModal}
          onHide={this.handleHide}
          buttonStyle={'primary'}
          buttonText={'Palaa etusivulle'}
        />
      );
    }
    let checked = false;
    if (this.state.toimintaryhma.saanayttaa == 1) {
          checked = true;
    } else {
        checked = false;
    }
    return (
      <Wrapper>
        {this.state.showModal ? modalContent : null}
        <Row className='ToimintamuotoOtsikko'>
          <Col className='col-xs-2'>
            <Button onClick={() => this.props.goTo('Toimintamuoto')}>
              <Glyphicon glyph='arrow-left' />
            </Button>
          </Col>
          <Col className='col-xs-8'>
            <h3>{this.props.otsikko}</h3>
          </Col>
          <Col className='col-xs-2'>
            <Tooltip
              placement='left'
              title='Toimintaryhmän muokkaus'
              ohjetekstinimi='tryhmanmuokkaus'
            />
          </Col>
        </Row>
        <form onSubmit={this.tallenna}>
          <h3>Muokkaa toiminnon tietoja</h3>
          <LabeledInput
            title='Nimi'
            name='nimi'
            type='text'
            value={this.state.toimintaryhma.nimi}
            placeholder='nimi'
            onChange={this.handleTextChange}
            maxLength='50'
          />
          <LabeledInput
            title='Alkupvm.'
            name='pvmalku'
            type='date'
            value={this.state.toimintaryhma.pvmalku}
            placeholder='pvmAlku'
            onChange={this.handleTextChange}
            maxLength='10'
          />
          <LabeledInput
            title='Loppupvm (jos on / tiedossa)'
            name='pvmloppu'
            type='date'
            value={this.state.toimintaryhma.pvmloppu}
            placeholder='pvmloppu'
            onChange={this.handleTextChange}
            maxLength='10'
          />
          <LabeledInput
                    title='Missä toimintaa on?'
                    name='missatoimintaaon'
                    type='text'
                    value={this.state.toimintaryhma.missatoimintaaon}
                    placeholder='Missä?'
                    onChange={this.handleTextChange}
                    maxLength='250'
          />
         <LabeledInput
                    title='Milloin toimintaa on?'
                    name='tilanne'
                    type='text'
                    value={this.state.toimintaryhma.tilanne}
                    placeholder='Milloin?'
                    onChange={this.handleTextChange}
                    maxLength='250'
         />
         <LabeledInput
                    title='Mistä saa lisätietoa? (esim. linkki tai sposti)'
                    name='lisatietoa'
                    type='text'
                    value={this.state.toimintaryhma.lisatietoa}
                    placeholder='Mistä saa lisätietoa?'
                    onChange={this.handleTextChange}
                    maxLength='500'
         />
         <Checkbox
                    onChange={this.handleBoxClick}
                    checked={checked}
         >
         nämä tiedot saa näyttää MLL nettisivuilla ss
         </Checkbox>
         <FormControl.Feedback />
         <Button bsClass='TallennaRyhma' type='submit'>
            Tallenna ryhmä
         </Button>
        </form>
      </Wrapper>
    );
  }
}
//defaultValue={this.state.toimintaryhma.aikuisiavuodessa}
//defaultValue={this.state.toimintaryhma.aikuisiaperkerta}
//defaultValue={this.state.toimintaryhma.lapsiavuodessa}
//defaultValue={this.state.toimintaryhma.lapsiaperkerta}

ToimintaryhmaLomake.propTypes = {
    toimintamuotoid: PropTypes.number,
    toimintaryhmaid: PropTypes.number,
    kayttajaId: PropTypes.number
};
export default ToimintaryhmaLomake;
