import React, { Component } from 'react';
import { Row, Col, Button, Panel } from 'react-bootstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import '../../../../../styles/indikaattorilomake.css';
import Monivalinta from '../../../../../hoc/FormComponents/Piiri/Monivalinta';
import Otsikko from '../../../../../hoc/FormComponents/Piiri/Otsikko';
import Vaittama from '../../../../../hoc/FormComponents/Piiri/Vaittama';
import Lisatieto from '../../../../../hoc/FormComponents/Piiri/Lisatieto';
import { apiBaseUrl, authString } from '../../../../../urlPaths';
import ProgressBar from '../../../../../hoc/FormComponents/Progressbar';

class IndikaattoriLomake extends Component {
  state = {
    kaikkiOhjausindikaattorit: [],
    ohjausindikaattorit: [],
    indikaattoritLuotu: false,
    ohjetekstit: [],
    naytaKaikki: false,
    loading: true
  };

  componentDidMount() {
    this.haeOhjausIndikaattorit();
    if (this.props.indikaattorit.length > 0) {
      this.setState({ indikaattoritLuotu: true });
    }
  }

  haeOhjausIndikaattorit = () => {
    axios
      .get(`${apiBaseUrl}HaeOhjausIndikaattorit`, {
        headers: {
          Authorization: authString(),
          seurantamerkintatyyppi: this.props.luokkaId // LuokkaId 1 on yhdistys, 2= Perhekahvila
        }
      })
      .then(response => {
        this.setState({
          kaikkiOhjausindikaattorit: response.data
        });
        this.suodataIndikaattorit();
      })
      .catch(error => {
        console.log(error);
      });
  };

  suodataIndikaattorit = () => {
      let suodatetut = [];
        if (this.props.indikaattorit.length > 0) {
            this.props.indikaattorit.forEach(ind => {
              this.state.kaikkiOhjausindikaattorit.forEach(oi => {
              // BackEnd returns sometimes oiId instead of OiId
              if (oi.id === ind.oiid) {
                suodatetut.push(oi);
              }
        });
      });
      suodatetut.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
      this.setState({
        ohjausindikaattorit: suodatetut,
        loading: false
      });
    } else if (this.state.kaikkiOhjausindikaattorit !== null) {
      suodatetut = this.state.kaikkiOhjausindikaattorit.filter(
        ind => ind.seurantamerkintatyyppi === this.props.luokkaId
      );
      if (this.state.naytaKaikki === false) {
        suodatetut = suodatetut.filter(ind => ind.kysyttavat !== 3);
      } else if (this.state.naytaKaikki === true) {
        suodatetut = suodatetut.filter(ind => ind.kysyttavat !== 2);
      }
      this.setState({
        ohjausindikaattorit: suodatetut,
        loading: false
      });
    }
  };

  handleButtonClick = event => {
    this.setState({ loading: true });
    if (event.target.name === 'kaikki') {
      this.setState({ naytaKaikki: true }, () => this.suodataIndikaattorit());
    }
    if (event.target.name === 'kokonais') {
      this.setState({ naytaKaikki: false }, () => this.suodataIndikaattorit());
    }
  };

  luoIndikaattorit = () => {
    const indikaattorit = [];
    this.state.ohjausindikaattorit.forEach(i => {
      const indikaattori = {
        oiid: i.id,
        smid: 0,
        numero: i.indtyyppi === 1 ? i.vastaus : null,
        vastaus: i.indtyyppi === 2 ? i.vastaus : '',
        monivalinta: i.indtyyppi >= 3 ? true : false,
        ohjausindikaattori: i,
        seurantamerkinta: [],
        monivalintavastaukset: i.indtyyppi < 3 ? [] : i.monivalintavastaukset
      };
      indikaattorit.push(indikaattori);
    });
    this.setState({ indikaattoritLuotu: true });
    return indikaattorit;
  };

  lisaaVastaus = ind => {
    let indikaattorit = [];
    if (!this.props.smedit) {
      indikaattorit = this.luoIndikaattorit();
    } else if (this.props.smedit && !this.state.indikaattoritLuotu) {
      indikaattorit = this.luoIndikaattorit();
    } else {
      indikaattorit = this.props.indikaattorit;
        indikaattorit.forEach((indikaattori, i) => {
        
        if (indikaattori.oiid === ind.id ) {
          if (ind.indtyyppi === 1) {
            indikaattorit[i].numero = ind.vastaus;
          }
          if (ind.indtyyppi === 2) {
            indikaattorit[i].vastaus = ind.vastaus;
          }
          if (ind.indtyyppi > 2) {
            indikaattorit[i].monivalintavastaukset = ind.monivalintavastaukset;
          }
        }
      });
    }
    this.props.handleIndikaattoritChange(indikaattorit);
  };

  render() {
    let OhjausIndikaattorit = null;
    if (this.state.loading === true) {
      OhjausIndikaattorit = (
        <Panel>
          <h4>Haetaan...</h4>
          <ProgressBar />
        </Panel>
        );
        return (
            <div className='LomakeWrapperi'>
                {OhjausIndikaattorit}
            </div>
        );
    } else {
      const indikaattorit = this.props.indikaattorit;
      OhjausIndikaattorit = this.state.ohjausindikaattorit.map(ind => {
        let indikaattori = null;
          indikaattorit.forEach(i => {
          // BackEnd returns sometimes oiId instead of OiId
          if (i.oiid === ind.id ) {
            indikaattori = i;
          }
        });
        switch (ind.indtyyppi) {
          case 0:
            return <Otsikko key={ind.id} ohjausmerkinta={ind} />;
          case 1:
            return (
              <Vaittama
                key={ind.id}
                ohjausmerkinta={ind}
                lisaaVastaus={this.lisaaVastaus}
                vastaus={indikaattori !== null ? indikaattori.numero : ''}
              />
            );
          case 2:
            return (
              <Lisatieto
                key={ind.id}
                ohjausmerkinta={ind}
                lisaaVastaus={this.lisaaVastaus}
                vastaus={indikaattori !== null ? indikaattori.vastaus : ''}
              />
            );
          case 3:
            return (
              <Monivalinta
                key={ind.id}
                ohjausmerkinta={ind}
                lisaaVastaus={this.lisaaVastaus}
                monivalinnat={
                  indikaattori !== null
                    ? indikaattori.monivalintavastaukset
                    : []
                }
              />
            );
          case 4:
            return (
              <Monivalinta
                key={ind.id}
                ohjausmerkinta={ind}
                lisaaVastaus={this.lisaaVastaus}
                monivalinnat={
                  indikaattori !== null
                    ? indikaattori.monivalintavastaukset
                    : []
                }
              />
            );
          case 5:
            return (
              <Monivalinta
                key={ind.id}
                ohjausmerkinta={ind}
                lisaaVastaus={this.lisaaVastaus}
                monivalinnat={
                  indikaattori !== null
                    ? indikaattori.monivalintavastaukset
                    : []
                }
              />
            );
          case 6:
            return (
              <Monivalinta
                key={ind.id}
                ohjausmerkinta={ind}
                lisaaVastaus={this.lisaaVastaus}
                monivalinnat={
                  indikaattori !== null
                    ? indikaattori.monivalintavastaukset
                    : []
                }
              />
            );
          default:
            return null;
        }
      });
    }

    return (
      <div className='LomakeWrapperi'>
        {this.props.indikaattorit.length > 0 ? null : (
          <Row>
            <Col className='col-xs-6'>
              <Button
                name='kokonais'
                className='ValintaNappi'
                bsSize='lg'
                onClick={this.handleButtonClick}
              >
                Kokonaistilanne
              </Button>
            </Col>
            <Col className='col-xs-6'>
              <Button
                name='kaikki'
                className='ValintaNappi'
                bsSize='lg'
                onClick={this.handleButtonClick}
              >
                Kaikki indikaattorit
              </Button>
            </Col>
          </Row>
        )}

        {OhjausIndikaattorit}
      </div>
    );
  }
}

IndikaattoriLomake.propTypes = {
  lisaaIndikaattorit: PropTypes.func.isRequired,
  handleIndikaattoritChange: PropTypes.func.isRequired,
  luokkaId: PropTypes.number,
  indikaattorit: PropTypes.array,
  smedit: PropTypes.bool
};

export default IndikaattoriLomake;
