import React, { Component } from 'react';
import axios from 'axios';
import { Panel, Col, Row } from 'react-bootstrap';
import { apiBaseUrl, authString } from '../../../../../urlPaths';
import '../../../../../styles/yhdistys.css';
import Progressbar from '../../../../../hoc/FormComponents/Progressbar';
import AlertMessage from '../../../../../hoc/FormComponents/AlertMessage';
import TapahtumaListaRivi from './TapahtumaListaRivi/TapahtumaListaRivi';

class TapahtumienSelausLista extends Component {
  state = {
    tapahtumat: [],
    filteredTapahtumat: [],
    loading: false,
    error: false,
    errorMessage: null
  };

  componentDidMount() {
    if (this.state.tapahtumat.length === 0) {
      this.setState({ loading: true });
      this.haeTapahtumat(this.props.organisaatioId);
    }
  }

  haeTapahtumat = org => {
    axios
      .get(`${apiBaseUrl}HaeTapahtumaLista?org=${org}`, {
        headers: { Authorization: authString() }
      })
      .then(response => {
        const tapahtumat = response.data;
        // Ei voi pilkkoa vielä tässä: const slicedTapahtumat = tapahtumat.slice(0, 20);
        this.setState(
          {
            tapahtumat: tapahtumat,
            loading: false
          },
          () => {
            const filtered = this.filterTapahtumat(
              this.props.tapahtumaTaso
            ).slice(0, 30);
            this.setState({
              filteredTapahtumat: filtered
            });
          }
        );
        console.log(this.state.tapahtumat);
        console.log(this.state.filteredTapahtumat);
      })
      .catch(error => {
        this.setState({
          error: true,
          errorMessage: error.message,
          loading: false
        });
        console.log(error);
      });
  };

  filterTapahtumat = tapahtumaTaso => {
    let filtered = null;
    if (tapahtumaTaso === 'Organisaatio') {
      filtered = this.state.tapahtumat.filter(
        tapahtuma =>
          tapahtuma.organisaatioId === this.props.organisaatioId &&
          tapahtuma.tyyppi === 0
      );
    } else if (tapahtumaTaso === 'Toimintamuoto') {
      filtered = this.state.tapahtumat.filter(
        tapahtuma =>
          tapahtuma.organisaatioId === this.props.organisaatioId &&
          tapahtuma.toimintamuotoId === this.props.toimintamuotoId &&
          tapahtuma.tyyppi === 1
      );
    } else if (tapahtumaTaso === 'Toimintaryhma') {
      filtered = this.state.tapahtumat.filter(
        tapahtuma =>
          tapahtuma.toimintamuotoId === this.props.toimintamuotoId &&
          tapahtuma.toimintaryhmaId === this.props.toimintaryhmaId &&
          tapahtuma.tyyppi === 2
      );
    }
    return filtered;
  };

  render() {
    let pageContent = null;

    if (this.state.error) {
      pageContent = (
        <AlertMessage
          alertStyle='danger'
          message={
            'Tapahtumien haussa tapahtui virhe. Käynnistä sovellus uudelleen MLL logosta.'
          }
          details={this.state.errorMessage}
        />
      );
    } else if (this.state.loading) {
      pageContent = (
        <Panel>
          <h4>Haetaan...</h4>
          <Progressbar />
        </Panel>
      );
    }
    if (this.state.filteredTapahtumat.length === 0) {
      pageContent = (
        <Panel>
          <h4>Ei lisättyjä tapahtumia.</h4>
        </Panel>
      );
    } else {
      pageContent = (
        <div>
          <Row style={{ height: '50px', padding: '15px' }}>
            <Col className='col-xs-12'>
              <h3>Tilastomerkinnät</h3>
            </Col>
          </Row>
          <hr />
          {this.state.filteredTapahtumat.map((tapahtuma, index) => (
            <TapahtumaListaRivi
              key={index}
              tapahtuma={tapahtuma} 
              modify={this.props.modify}
              delete={this.props.delete}
              tapahtumaTaso={this.props.tapahtumaTaso}
              handleTapahtumaTasoAndGoTo={this.props.handleTapahtumaTasoAndGoTo}
              handleTapahtumaId={this.props.handleTapahtumaId}
              orgId={this.props.organisaatioId}
              goTo={this.props.goTo}
            />
          ))}
        </div>
      );
    }
    return <div>{pageContent}</div>;
  }
}

export default TapahtumienSelausLista;
