import React, { Component } from 'react';
import { Button, Row, Col, Panel, Glyphicon } from 'react-bootstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import '../../../../../styles/seurantamerkintalista.css';
import { apiBaseUrl, authString } from '../../../../../urlPaths';
import moment from 'moment';
import Modal from '../../../../../hoc/FormComponents/Modal';
import AlertMessage from '../../../../../hoc/FormComponents/AlertMessage';
import ProgressBar from '../../../../../hoc/FormComponents/Progressbar';

const formatDate = date => moment(date).format('D.M.YYYY');
class SeurantamerkintaLista extends Component {
  state = {
    seurantamerkinnat: null,
    perheKahvilaSuodatus: false,
    yhdistysSuodatus: false,
    kuntaSuodatus: false,
    omaSuodatus: false,
    loading: true,
    error: false,
    errorMessage: null,
    showModal: false,
    selectedid: null
  };

  componentDidMount() {
    this.haeSeurantaMerkinnat(this.props.luokkaId);
  }

  //toteuta tähän vielä userid suodatus - this.state.omaSuodatus -> lähetä id
  haeSeurantaMerkinnat = (luokkaId) => {
    axios
      .get(`${apiBaseUrl}HaeSeurantamerkinnat`, {
        headers: {
          Authorization: authString(),
          org: this.props.organisaatioId,
          cnt: 200,
          suodatus: luokkaId,
          //perhe: kahvilasuodatus,
          //yhdistys: yhdistyssuodatus,
          userid: this.props.kayttajaId,
          kayttajatyyppi: this.props.kayttajaTyyppi,
        }
      })
      .then(response => {
        this.setState({ seurantamerkinnat: response.data, loading: false });
      })
      .catch(error => {
        this.setState({
          error: true,
          loading: false,
          errorMessage: error.message
        });
      });
    this.setState({ loading: true });
  };

  editSeurantamerkinta = seurantamerkintaId => {
    this.props.setSeurantamerkintaId(seurantamerkintaId);
    this.props.handlenakyma('seurantamerkintaedit');
  };


  showModal = (id) => {
     this.setState({ showModal: true, selectedid: id });
  }

  onDelete = () => {
     this.poistaTapahtuma();
     this.props.goTo('Piiri');
     this.setState({ showModal: false });
  }

  onCancel = () => {
    this.setState({ showModal: false });
    }

  poistaTapahtuma = () => {
  const headers = {
       Authorization: authString(),
       id: this.state.selectedid,
   };
   axios
      .get(`${apiBaseUrl}PoistaSeurantamerkinta`, { headers })
       .then(response => {
           this.componentDidMount();  // Luo ruudun uudelleen poiston jälkeen
       })
       .catch(error => {
           this.componentDidMount();  // Luo ruudun uudelleen poiston jälkeen
       });
  };


  handleButtonClick = event => {
    switch (event.target.name) {
      case 'kaikki':
        this.setState({
          perheKahvilaSuodatus: false,
          omaSuodatus: false,
          yhdistysSuodatus: false
        });
        break;
      case 'perhekahvilat':
        this.setState({
          naytaKaikki: false,
          perheKahvilaSuodatus: !this.state.perheKahvilaSuodatus
        });
        break;
      case 'yhdistykset':
        this.setState({
          naytaKaikki: false,
          yhdistysSuodatus: !this.state.yhdistysSuodatus
        });
        break;
      case 'kunnat':
            this.setState({
                naytaKaikki: false,
                kuntaSuodatus: !this.state.kuntaSuodatus
            });
      break;
      case 'omat':
        this.setState({
          naytaKaikki: false,
          omaSuodatus: !this.state.omaSuodatus
        });
        break;
      default:
        break;
    }
    this.haeSeurantaMerkinnat();
  };

    render() {
    
    if (this.state.showModal === true) {
       return (
            <Modal
                message={'Haluatko varmasti poistaa!'}
                details={this.state.errorMessage}
                messageStyle={'danger'}
                title={'Poisto'}
                show={this.state.showModal}
                onHide={this.onDelete}
                onCancel={this.onCancel}
                buttonStyle={'primary'}
                buttonText={'Poista'}
                withCancel={true}
            />
        );
    }
    let Merkintanakyma = null;

    if (this.state.error) {
      Merkintanakyma = (
        <AlertMessage
          alertStyle='danger'
          message={
            'Haussa tapahtui virhe. Käynnistä sovellus uudelleen MLL logosta.'
          }
          details={this.state.errorMessage}
        />
        );
       return Merkintanakyma;
    };

    if (this.state.loading) {
      Merkintanakyma = (
        <Panel>
          <h4>Haetaan...</h4>
          <ProgressBar />
        </Panel>
        );
       return Merkintanakyma;
     } 

     // Tiedot ladattu. Kehitellään näkymää
      let merkinnat = [];
        this.state.seurantamerkinnat.forEach(merk => {
          let apuotsake = '';
          if (merk.organisaatiot.length > 0) apuotsake = merk.organisaatiot[0].lyhytnimi; // lisätään lyhytnimi jos on
          if (merk.organisaatiot.length > 1) {
              apuotsake = 'Useita organisaatioita';
          }
          merk.lisatieto = apuotsake;  // Viedään lisätietokenttään niin ei tarvitse ropata rakennetta. Tällä ei listanäkymässä ole muuta käyttöä..
          merkinnat.push(merk);

     });

     // Merkintanakyma = this.state.seurantamerkinnat.map(merk => (
     Merkintanakyma = merkinnat.map(merk => (

        <div key={merk.id} className='MerkintalistausWrapperi'>
          <Row>
            <Col className='col-xs-8'>
              <strong className='MerkintaRivi'>
                {merk.seurantamerkintaotsikko}
              </strong>
            </Col>
            <Col className='col-xs-4'>{formatDate(merk.pvm)}</Col>
          </Row>
          <Row>
                    <Col className='col-xs-6 MerkintaRivi'>{merk.omaotsikko}</Col>
                    <Col className='col-xs-6 MerkintaRivi'>{merk.lisatieto}</Col>
          </Row>
          <Row>
                  <Col className='col-xs-12 MerkintaRivi'>{merk.kirjaaja}</Col>
          </Row>
          <Row>
            <Col className='col-xs-12 MerkintaRivi'>
              <Button
                className='MerkintariviButtoni'
                key={merk.id}
                id={merk.id}
                bsClass='MerkintariviWrapperi'
                onClick={() => this.editSeurantamerkinta(merk.id)}
              >
                Muokkaa
              </Button>
              <Button
                    className='MerkintariviButtoniAlert'
                    bsClass='MerkintariviWrapperi'
                    onClick={() => this.showModal(merk.id)}
              >
                Poista
              </Button>
            </Col>
           </Row>
        


        </div>
     ));

     let merkintaotsikko = '';
     switch (this.props.luokkaId) {
         case 1:
             merkintaotsikko = (<h4 className='MerkintaOtsikko'>Yhdistyksen ohjaus</h4>);
             break;
         case 2:
             merkintaotsikko = (<h4 className='MerkintaOtsikko'>Perhekahvilan ohjaus</h4>);
             break;
         case 3:
             merkintaotsikko = (<h4 className='MerkintaOtsikko'>Muut merkinnät</h4>);
             break;
         default: {
             merkintaotsikko = (<h4 className='MerkintaOtsikko'>Merkintäluokka puuttuu. Ilmoita kehittäjälle!</h4>);
         }
     }
      return (
      <div className='MerkintalistausWrapperi'>
        <Row>
          <Col className='col-xs-12'>
            
           {merkintaotsikko}
          </Col>
        </Row>
        <Row>
           {this.props.luokkaId === 3 ? (
               <Col></Col>
             ) : (
               <Col>{this.props.yhdistysNimi}</Col>
             )}
        </Row>

        <Row style={{ paddingBottom: '30px' }}>
          <Col className='col-xs-6'>
            <Button onClick={() => this.props.handlenakyma('piirisivu')}>
              <Glyphicon glyph='arrow-left' />
            </Button>
          </Col>
          <Col className='col-xs-6'>
            <Button
              bsStyle='success'
              className='btn-success-new'
              bsSize='large'
              //className='pull-right'
              style={{ margin: '0px' }}
              onClick={() => this.props.handlenakyma('seurantamerkintanew')}
            >
              Lisää Uusi
            </Button>
          </Col>
        </Row>
        {Merkintanakyma}
      </div>
    );
  }
}

SeurantamerkintaLista.propTypes = {
  luokkaId: PropTypes.number,
  organisaatioId: PropTypes.number,
  kayttajaId: PropTypes.number,
  handlenakyma: PropTypes.func,
  yhdistysNimi: PropTypes.string,
  setSeurantamerkintaId: PropTypes.func,
  goTo: PropTypes.func.isRequired,
  kayttajaTyyppi: PropTypes.number,
};

export default SeurantamerkintaLista;
