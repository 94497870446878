import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../../../hoc/wrapper/Wrapper';
import { Row, Glyphicon, Col, Button } from 'react-bootstrap';
import Toimintaryhmatlista from './Toimintaryhmat/Toimintaryhmatlista';
import Tooltip from '../../../hoc/FormComponents/Tooltip';

class Toimintamuoto extends Component {
  state = {
    loading: false,
    error: false
  };

    render() {
        let toimintamuotoinfo = null;
        if (this.props.debug === true) {
            toimintamuotoinfo = 'toimintamuotoId: ' + this.props.toimintamuotoId;
        }
    return (
      <Wrapper>
        <Row className='ToimintamuotoOtsikko'>
          <Col className='col-xs-2'>
            <Button onClick={() => this.props.goTo('Yhdistys')}>
              <Glyphicon glyph='arrow-left' />
             </Button>
          </Col>
          <Col className='col-xs-8'>
            <h3>{this.props.otsikko}</h3>
            <h5>
              {toimintamuotoinfo} 
            </h5>
          </Col>
          <Col className='col-xs-2'>
            <Tooltip
              placement='left'
              title='Toimintamuodon toimintryhmät'
              ohjetekstinimi='toimintamuoto'
            />
          </Col>
        </Row>
        <Row>
          <Col className='col-xs-6'>
            <Button
              bsClass='LinkkiNappi'
              onClick={() => this.props.goTo('ToimintaryhmaInsertLomake')}
            >
              Lisää ryhmä tai toiminto
            </Button>
          </Col>
          <Col className='col-xs-6'>
            <Button
              bsClass='LinkkiNappi'
              onClick={() => this.props.handleTapahtumaTasoAndGoTo('Toimintamuoto', 'Tapahtuma')}
            >
              Tilastoi kaikki
            </Button>
          </Col>
        </Row>
        <br />
        <br />
        <Toimintaryhmatlista
          organisaatioid={this.props.organisaatioId}
          toimintamuotoid={this.props.toimintamuotoId}
          goTo={this.props.goTo}
          luoUusi={this.state.lisaaRyhma}
          handleData={this.props.handleData}
          handleTapahtumaTasoAndGoTo={this.props.handleTapahtumaTasoAndGoTo}
          handleToimintaryhmaIdAndNimi={this.props.handleToimintaryhmaIdAndNimi}
        />
      </Wrapper>
    );
  }
}

Toimintamuoto.propTypes = {
  organisaatioId: PropTypes.number,
  toimintamuotoId: PropTypes.number,
  goTo: PropTypes.func,
  otsikko: PropTypes.string
};

export default Toimintamuoto;
