/* Lisää kutsuvaan ohjelmaan, jossa statea ylläpidetään, tällainen inputhandler:

   handleInputChange = (updateobjectname, value) => {
   const toim_ryhma = {...this.state.toimintaryhma };
   toim_ryhma[updateobjectname] = value;
   this.setState({toimintaryhma: toim_ryhma})
   };

    kutsu sieltä tätä komponenttia näin:
    <CommonLabeledNumberPicker
    title='Aikuisia / kerta'
    name='aikuisiaperkerta'
    value={this.state.toimintaryhma.aikuisiaperkerta}
    onChange={this.handleInputChange}
    />

 */

import React, { Component } from 'react';
import { ControlLabel, Button, Row, Col, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../../styles/tilastolomake.css';
import Tooltip from '../Tooltip';

const style = {
  margin: '5px',
  borderTop: 'solid gray',
  padding: '5px'
};

class CommonLabeledNumberPicker extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this); // Käsin muutos
    this.handleChangePlus = this.handleChangePlus.bind(this); // Nappi
    this.handleChangeMinus = this.handleChangeMinus.bind(this); // Nappi
  }

    handleChangePlus = e => {
    const newvalue = parseInt(this.props.value, 10) + 1;
    this.props.onChange(e.target.name, newvalue);
  }

    handleChangeMinus = e => {
    const newvalue =
      this.props.value > 0 ? parseInt(this.props.value, 10) - 1 : 0;
    this.props.onChange(e.target.name, newvalue);
  }

    handleChange = e => {
    var parsed = parseInt(e.target.value, 10);
    this.props.onChange(e.target.name, parsed);
    }

   

    render() {

        let tip = null;

        if (typeof(this.props.tooltip) != 'undefined') {
            tip = <Col className='col-xs-1'>
                <Tooltip
                    placement='right'
                    location='left'
                    ohjetekstinimi={this.props.tooltip}
                    title={this.props.title}
                    content='tähän pitäisi lisätä infotekstiä :)'
                />
            </Col>
        }
        else {
            tip = <Col className='col-xs-1'></Col>
        }

        return (
      <div style={style}>
        <Row>
          <Col className='col-xs-12'>
            <ControlLabel>{this.props.title}</ControlLabel>
            <p>{this.props.tarkennukset}</p>
          </Col>
        </Row>
        <Row>
         {tip}
          <Col className='col-xs-3'>
            <Button
              bsStyle='danger'
              name={this.props.name}
              className='PlusMiinusNappi'
              onClick={this.handleChangeMinus}
            >
              -
            </Button>
          </Col>
          <Col className='col-xs-1'></Col>
          <Col className='col-xs-4'>
            <FormControl
              type='integer'
              value={this.props.value}
              placeholder={this.props.value}
              onChange={this.handleChange}
              name={this.props.name}
             />
          </Col>
          <Col className='col-xs-3'>
            <Button
              bsStyle='success'
              name={this.props.name}
              className='PlusMiinusNappi'
              onClick={this.handleChangePlus}
            >
              +
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

CommonLabeledNumberPicker.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  tarkennukset: PropTypes.string,
  name: PropTypes.string,
  tooltip: PropTypes.string
};
export default CommonLabeledNumberPicker;
