import React, { Component } from 'react';
import { Row, Button } from 'react-bootstrap';
import { apiBaseUrl, authString } from '../../urlPaths';
import Wrapper from '../../hoc/wrapper/Wrapper';
import axios from 'axios';
import PropTypes from 'prop-types';
import '../../styles/linkkinappi.css';
import Toimintamuodot from '../../components/Yhdistys/Toimintamuodot';
import PiiriSivu from '../../components/Piiri/PiiriSivu';
import Toimintamuoto from '../../components/Yhdistys/Toimintamuoto/Toimintamuoto';
import ToimintamuotoLomake from '../../components/Yhdistys/Toimintamuoto/ToimintamuotoLomake/ToimintamuotoLomake';
import ToimintamuodonAloitus from '../../components/Yhdistys/Toimintamuoto/ToimintamuodonAloitus/ToimintamuodonAloitus'; // eslint-disable-line
import Tapahtuma from '../../components/Yhdistys/Toimintamuoto/Tapahtuma/Tapahtuma';
import TapahtumanLisays from '../../components/Yhdistys/Toimintamuoto/Tapahtuma/TapahtumaLomake/TapahtumanLisays';
import TapahtumanMuokkaus from '../../components/Yhdistys/Toimintamuoto/Tapahtuma/TapahtumaLomake/TapahtumanMuokkaus';
import Toimintaryhma from '../../components/Yhdistys/Toimintamuoto/Toimintaryhma/Toimintaryhma';
import ToimintaryhmaLomake from '../../components/Yhdistys/Toimintamuoto/Toimintaryhma/ToimintaryhmaLomake/ToimintaryhmaLomake';
import ToimintaryhmaInsertLomake from '../../components/Yhdistys/Toimintamuoto/Toimintaryhma/ToimintaryhmaLomake/ToimintaryhmaInsertLomake';
import Tooltip from '../../hoc/FormComponents/Tooltip';

class Home extends Component {
  displayName = Home.name;

  state = {
    debug: false,
    kayttajaTiedot: null,
    organisaatioId: null,
    organisaatioOverrideId: null,
    organisaatioOverrideNimi: null,
    kayttajaTyyppi: null,
    luokkaId: null, // 1 = Yhdistys, 2=Perhekahvila. Piirin etusivun napit p��tt��.
    piiriId: null, // 1 tai 2 kokeillessa
    piiriNimi: null,
    seurantamerkintaId: null,
    kayttajaId: null,
    kayttajaNimi: null,
    viewName: 'Home',
    toimintamuotootsikot: [],
    toimintamuotoid: null,
    toimintamuotootsikkoId: null,
    otsikko: null,
    toimintaryhmaId: null,
    toimintaryhmaNimi: null,
    yhdistysNimi: null,
    tapahtumaTaso: null,
    tapahtumaId: null,
    url: null,
  };

  componentDidMount() {
    this.getOrganisation();
  }

  getOrganisation = () => {
    axios
      .get(`${apiBaseUrl}HaeKayttajatiedot`, {
        headers: { Authorization: authString() }
      })
      .then(response => {
        const data = response.data;
        this.setState({
          kayttajaTiedot: data,
          yhdistysNimi:
            this.state.organisaatioOverrideId !== null
              ? this.state.organisaatioOverrideNimi
              : data.item3.pitkaNimi,
          organisaatioId:
            this.state.organisaatioOverrideId !== null
              ? this.state.organisaatioOverrideId
              : data.item3.id, // Organisaatio override id piiripuolelta
          piiriId: data.item3.piiriID,
          piiriNimi: data.item4 !== null ? data.item4.piiriNimi : '',
          kayttajaTyyppi: data.item2, // Jos käyttäjätyyppi = 1 kyseessä yhdistyskäyttäjä. Käyttäjätyyppi = 2 Piirikäyttäjä. kt 3=MLL pääkäyttäjä.
          kayttajaId: data.item1,
          kayttajaNimi: data.item5,
          url: data.item3.url,
        });
        console.log(data);
      })

      .catch(error => {
        console.log(`error${error}`);
      });
  };

  goToHandler = goTo => {
    this.setState({
      viewName: goTo
    });
  };

  yhdistysOverride = (yhdistys, nimi) => {
    this.setState({
      organisaatioId: yhdistys,
      organisaatioOverrideId: yhdistys,
      organisaatioOverrideNimi: nimi
    });
  };

  handleData = (id, toimintamuotoid, nimi) => {
    this.setState({
      toimintamuotootsikkoId: id,
      toimintamuotoid,
      otsikko: nimi
    });
  };

  handleArrayData = array => {
    this.setState({
      toimintamuotootsikot: array
    });
  };

  handleTapahtumaTasoAndGoTo = (taso, goTo) => {
    this.setState({
      tapahtumaTaso: taso
    });
    this.goToHandler(goTo);
  };

  handleTapahtumaId = value => {
    this.setState({
      tapahtumaId: value
    });
  };

  handleToimintaryhmaIdAndNimi = (toimintaryhmaId, toimintaryhmaNimi) => {
    this.setState({
      toimintaryhmaId,
      toimintaryhmaNimi
    });
  };

  handleDataAndGoTo = (id, toimintamuotoid, otsikko, goTo) => {
    if (id && otsikko) {
      this.handleData(id, toimintamuotoid, otsikko);
    }
    if (goTo) {
      this.goToHandler(goTo);
    }
  };

  handlePiiriDataAndGoTo = (luokkaId, seurantamerkintaId, goTo) => {
    if (luokkaId && goTo) {
      this.setState({ luokkaId }, () => {
        this.goToHandler(goTo);
      });
    }
    if (seurantamerkintaId && goTo) {
      this.setState({ seurantamerkintaId }, () => {
        this.goToHandler(goTo);
      });
    }
  };

  render() {
    if (this.state.piiriId === null && this.state.organisaatioId === null) {
      return (
        <Wrapper>
          <h3>Ladataan MLL Seurantaa...</h3>
        </Wrapper>
      );
      }
    if (this.state.organisaatioId === 0) {
    return (
        <Wrapper>
        <h3>Sinulla ei ole järjestelmän käyttöoikeutta!</h3>
        </Wrapper>
     );
     }

    var urli = "";
      if (this.state.url !== null) {
        urli = <a href={this.state.url} target="_blank">Piirin yhteystiedot</a>
    }

    let debuginfo = null;
    const yhdistys = (
      <Toimintamuodot
        handleData={this.handleData}
        goTo={this.goToHandler}
        handleDataAndGoTo={this.handleDataAndGoTo}
        organisaatioId={
          this.state.organisaatioOverrideId !== null
            ? this.state.organisaatioOverrideId
            : this.state.organisaatioId
        } // Yliajetaan piiripuolella asetetulla jos tarpeen
        overrideMode={this.state.organisaatioOverrideId !== null ? true : false}
        toimintamuotoId={this.state.toimintamuotoid}
        handleArrayData={this.handleArrayData}
        debug={this.state.debug}
        toimintamuotootsikot={this.state.toimintamuotootsikot}
        kayttajaId={this.state.kayttajaId}
        kayttajaTyyppi={this.state.kayttajaTyyppi}
        otsikko={this.state.otsikko}
        yhdistysNimi={
          this.state.organisaatioOverrideId !== null
            ? this.state.organisaatioOverrideNimi
            : this.state.yhdistysNimi
        }
        handleTapahtumaTasoAndGoTo={this.handleTapahtumaTasoAndGoTo}
      />
    );
    const piiri = (
      // Huom! yhdistysOverrideä käytetään Piiripuolella asettamaan organisaatioksi siellä valittu yhdistys.
      <PiiriSivu
        organisaatioId={this.state.organisaatioId}
        organisaatioOverrideId={this.state.organisaatioOverrideId}
        yhdistysNimi={
          this.state.organisaatioOverrideId !== null
            ? this.state.organisaatioOverrideNimi
            : this.state.yhdistysNimi
        }
        piiriId={this.state.piiriId}
        piiriNimi={this.state.piiriNimi}
        kayttajaId={this.state.kayttajaId}
        kayttajaTyyppi={this.state.kayttajaTyyppi} // 1 = Yhdistys, ei sallitu, 2 = Piiri, 3 = MLL
        yhdistysOverride={this.yhdistysOverride}
        goTo={this.goToHandler}
      />
    );
    const toimintamuoto = (
      <Toimintamuoto
        toimintamuotoId={this.state.toimintamuotoid}
        organisaatioId={this.state.organisaatioId}
        handleData={this.handleData}
        goTo={this.goToHandler}
        otsikko={this.state.otsikko}
        kayttajaId={this.state.kayttajaId}
        handleTapahtumaTasoAndGoTo={this.handleTapahtumaTasoAndGoTo}
        handleToimintaryhmaIdAndNimi={this.handleToimintaryhmaIdAndNimi}
        debug={this.state.debug}
      />
    );

    const toimintamuotolomake = (
      <ToimintamuotoLomake
        toimintamuotoId={this.state.toimintamuotoid}
        organisaatioId={this.state.organisaatioId}
        handleData={this.handleData}
        goTo={this.goToHandler}
        otsikko={this.state.otsikko}
        kayttajaId={this.state.kayttajaId}
      />
    );
    const toimintamuodonaloitus = (
      <ToimintamuodonAloitus
        toimintamuotootsikkoId={this.state.toimintamuotootsikkoId}
        otsikko={this.state.otsikko}
        handleDataAndGoTo={this.handleDataAndGoTo}
        goTo={this.goToHandler}
        kayttajaId={this.state.kayttajaId}
        organisaatioId={this.state.organisaatioId}
      />
    );
    const tapahtuma = (
      <Tapahtuma
        otsikko={this.state.otsikko}
        handleData={this.handleData}
        goTo={this.goToHandler}
        organisaatioId={this.state.organisaatioId}
        toimintaryhmaId={this.state.toimintaryhmaId}
        toimintaryhmaNimi={this.state.toimintaryhmaNimi}
        tapahtumaTaso={this.state.tapahtumaTaso}
        toimintamuotoId={this.state.toimintamuotoid}
        yhdistysNimi={
          this.state.organisaatioOverrideId !== null
            ? this.state.organisaatioOverrideNimi
            : this.state.yhdistysNimi
        }
        handleTapahtumaTasoAndGoTo={this.handleTapahtumaTasoAndGoTo}
        handleTapahtumaId={this.handleTapahtumaId}
      />
    );
    const tapahtumanlisays = (
      <TapahtumanLisays
        tapahtumaTaso={this.state.tapahtumaTaso}
        organisaatioId={
          this.state.organisaatioOverrideId !== null
            ? this.state.organisaatioOverrideId
            : this.state.organisaatioId
        } // Yliajetaan piiripuolella asetetulla jos tarpeen
        toimintaryhmaId={this.state.toimintaryhmaId}
        toimintaryhmaNimi={this.state.toimintaryhmaNimi}
        toimintamuotoId={this.state.toimintamuotoid}
        yhdistysNimi={
          this.state.organisaatioOverrideId !== null
            ? this.state.organisaatioOverrideNimi
            : this.state.yhdistysNimi
        }
        handleTapahtumaTasoAndGoTo={this.handleTapahtumaTasoAndGoTo}
        kayttajaId={this.state.kayttajaId}
        otsikko={this.state.otsikko}
        goTo={this.goToHandler}
      />
    );

    const tapahtumanmuokkaus = (
      <TapahtumanMuokkaus
        tapahtumaTaso={this.state.tapahtumaTaso}
        organisaatioId={this.state.organisaatioId}
        toimintaryhmaId={this.state.toimintaryhmaId}
        toimintaryhmaNimi={this.state.toimintaryhmaNimi}
        toimintamuotoId={this.state.toimintamuotoid}
        yhdistysNimi={this.state.yhdistysNimi}
        handleTapahtumaTasoAndGoTo={this.handleTapahtumaTasoAndGoTo}
        kayttajaId={this.state.kayttajaId}
        otsikko={this.state.otsikko}
        goTo={this.goToHandler}
        tapahtumaId={this.state.tapahtumaId}
      />
    );

    const toimintaryhma = (
      <Toimintaryhma
        toimintamuotoid={this.state.toimintamuotoid}
        handleData={this.handleData}
        goTo={this.goToHandler}
        otsikko={this.state.otsikko}
        kayttajaId={this.state.kayttajaId}
        toimintaryhmaid={this.state.toimintaryhmaId}
        handleDataAndGoTo={this.handleDataAndGoTo}
      />
    );

    const toimintaryhmalomake = (
      <ToimintaryhmaLomake
        kayttajaId={this.state.kayttajaId}
        organisaatioId={this.state.organisaatioId}
        goTo={this.goToHandler}
        toimintamuotoid={this.toimintamuotoid}
        toimintaryhmanimi={this.state.toimintaryhmaNimi}
        toimintaryhmaid={this.state.toimintaryhmaId}
        otsikko={this.state.otsikko}
      />
    );

    const toimintaryhmainsertlomake = (
      <ToimintaryhmaInsertLomake
        goTo={this.goToHandler}
        toimintamuotoid={this.state.toimintamuotoid}
        kayttajaId={this.state.kayttajaId}
        organisaatioId={this.state.organisaatioId}
        otsikko={this.state.otsikko}
      />
    );

    let content = null;
    let piirisivu = null;
    const infoteksti =
      this.state.viewName === 'Home' ? (
        <Tooltip
          title='etusivu'
          ohjetekstinimi={'etusivu'}
          naytalaatikkonasivulla={true}
        />
      ) : null;
    if (this.state.kayttajaTyyppi > 1) {
      piirisivu = (
        <Button
          bsClass='LinkkiNappiLevea'
          onClick={() => this.goToHandler('Piiri')}
        >
          Ammatillinen ohjaus
        </Button>
      );
    }

    if (this.state.viewName === 'Yhdistys') {
      content = yhdistys;
    } else if (this.state.viewName === 'Piiri') {
      content = piiri;
    } else if (this.state.viewName === 'Toimintamuoto') {
      content = toimintamuoto;
    } else if (this.state.viewName === 'ToimintamuotoLomake') {
      content = toimintamuotolomake;
    } else if (this.state.viewName === 'ToimintamuodonAloitus') {
      content = toimintamuodonaloitus;
    } else if (this.state.viewName === 'Tapahtuma') {
      content = tapahtuma;
    } else if (this.state.viewName === 'TapahtumanLisays') {
      content = tapahtumanlisays;
    } else if (this.state.viewName === 'TapahtumanMuokkaus') {
      content = tapahtumanmuokkaus;
    } else if (this.state.viewName === 'Toimintaryhma') {
      content = toimintaryhma;
    } else if (this.state.viewName === 'ToimintaryhmaLomake') {
      content = toimintaryhmalomake;
    } else if (this.state.viewName === 'ToimintaryhmaInsertLomake') {
      content = toimintaryhmainsertlomake;
    } else {

      content = (
        <div>
          <Row>
            <Button
              bsClass='LinkkiNappiLevea'
              onClick={() => this.goToHandler('Yhdistys')}
            >
              Oma toiminta
            </Button>
          </Row>
              <Row>{piirisivu}</Row>
              
        </div>
      );
    }
    if (this.state.debug === true) {
          debuginfo = (
              <div>
                  <Row>KäyttäjäId: {this.state.kayttajaId}</Row>
                  <Row>Käyttäjätyyppi: {this.state.kayttajaTyyppi}</Row>
                  <Row>OrganisaatioId: {this.state.organisaatioId}</Row>
                  <Row>Yhdistysnimi: {this.state.yhdistysNimi}</Row>
                  <Row>PiiriId: {this.state.piiriId}</Row>
                  <Row>PiiriNimi: {this.state.piiriNimi}</Row>
                  <Row>viewName: {this.state.viewName}</Row>
                  <Row>TapahtumaId: {this.state.tapahtumaId}</Row>
                  <Row>
                      OrganisaatioOverrideId: {this.state.organisaatioOverrideId}
                  </Row>
              </div>
          );
      }
    return (
      <Wrapper>
            {content}
            {debuginfo}
            <div>
                <Row>
                    {urli}
                </Row>
            </div>
            <div>
                <Row>
                    <h5>{this.state.kayttajaNimi}</h5>
                </Row>
            </div>
        {infoteksti}
      </Wrapper>
    );
  }
}

Home.propTypes = {
  organisaatioId: PropTypes.number
};

export default Home;
