import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Glyphicon, Form } from 'react-bootstrap';
import '../../../../../styles/yhdistys.css';
import Wrapper from '../../../../../hoc/wrapper/Wrapper';
import LabeledInput from '../../../../../hoc/FormComponents/LabeledInput';
import CommonLabeledNumberPicker from '../../../../../hoc/FormComponents/Common/CommonLabeledNumberPicker';
import LabeledDecimalPicker from '../../../../../hoc/FormComponents/LabeledDecimalPicker';
import axios from 'axios';
import { apiBaseUrl, authString } from '../../../../../urlPaths';
import Modal from '../../../../../hoc/FormComponents/Modal';
import Tooltip from '../../../../../hoc/FormComponents/Tooltip';

class TapahtumanLisays extends Component {
  state = {
    tapahtuma: {},
    error: false,
    errorMessage: null,
    showModal: false,
    success: false
  };

  componentDidMount() {
    this.setDefaultFields();
  }

  setDefaultFields = () => {
    if (this.props.tapahtumaTaso === 'Organisaatio') {
      this.setState({
        tapahtuma: {
          Aikuisia: 0,
          KayttajaId: this.props.kayttajaId,
          Lapsia: 0,
          Lisatietoa: '',
          OrganisaatioId: this.props.organisaatioId,
          Otsikko: '',
          Paikka: '',
          Palaute: '',
          PvmTapahtuma: '',
          ToimintamuotoId: null,
          ToimintaryhmaId: null,
          Tyyppi: 0,
          Vapaaehtoisia: 0,
          alkupvm: '',
          loppupvm: '',
          toimintaryhmia: 0,
          tapahtumia: 0,
          vapehttuntejayht: 0,
          vapehtperkerta: 0

        }
      });
    }
    if (this.props.tapahtumaTaso === 'Toimintamuoto') {
      this.setState({
        tapahtuma: {
          Aikuisia: 0,
          KayttajaId: this.props.kayttajaId,
          Lapsia: 0,
          Lisatietoa: '',
          OrganisaatioId: this.props.organisaatioId,
          Otsikko: '',
          Paikka: '',
          Palaute: '',
          PvmTapahtuma: '',
          ToimintamuotoId: this.props.toimintamuotoId,
          ToimintaryhmaId: null,
          Tyyppi: 1,
          Vapaaehtoisia: 0,
          alkupvm: '',
          loppupvm: '',
          toimintaryhmia: 0,
          tapahtumia: 0,
          vapehttuntejayht: 0,
          vapehtperkerta: 0
        }
      });
    }
    if (this.props.tapahtumaTaso === 'Toimintaryhma') {
      this.setState({
        tapahtuma: {
          Aikuisia: 0,
          KayttajaId: this.props.kayttajaId,
          Lapsia: 0,
          Lisatietoa: '',
          OrganisaatioId: this.props.organisaatioId,
          Otsikko: '',
          Paikka: '',
          Palaute: '',
          PvmTapahtuma: '',
          ToimintamuotoId: this.props.toimintamuotoId,
          ToimintaryhmaId: this.props.toimintaryhmaId,
          Tyyppi: 2,
          Vapaaehtoisia: 0,
          alkupvm: '',
          loppupvm: '',
          toimintaryhmia: 0,
          tapahtumia: 0,
          vapehttuntejayht: 0,
          vapehtperkerta: 0
        }
      });
    }
  };

  handleInputChange = (updateobjectname, value) => {
    const tapahtumaupdate = { ...this.state.tapahtuma };
    tapahtumaupdate[updateobjectname] = value;
    this.setState({ tapahtuma: tapahtumaupdate });
  };

  handleTextChange = e => {
    const target = e.target;
    const kentta = target.attributes.name.nodeValue;
    const tapahtuma = this.state.tapahtuma;
    tapahtuma[kentta] = target.value;
    this.setState({ tapahtuma });
  };

  lisaaTapahtuma = e => {
    e.preventDefault();

    const tapahtumaData = this.state.tapahtuma;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: authString()
    };
    axios
      .post(`${apiBaseUrl}TallennaTapahtuma`, tapahtumaData, { headers })
      .then(response => {
        const responseData = response.data;
        console.log(responseData);
        this.setState({
          showModal: true,
          success: true
        });
      })
      .catch(error => {
        this.setState({
          error: true,
          errorMessage: error.message,
          showModal: true
        });
      });
    this.showModal();
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  handleHide = () => {
    if (this.state.error) {
      this.setState({
        showModal: false,
        error: false,
        errorMessage: null,
        success: false
      });
    } else {
      this.setState({ showModal: false }, () => {
        this.toggleGoTo(this.props.tapahtumaTaso);
      });
    }
  };

  toggleGoTo = taso => {
    this.props.handleTapahtumaTasoAndGoTo(taso, 'Tapahtuma');
  };

  render() {
    let modalContent;
    if (this.state.showModal && this.state.error) {
      modalContent = (
        <Modal
          message={'Tallennus epäonnistui'}
          details={this.state.errorMessage}
          messageStyle={'danger'}
          title={'Tilastomerkinnnän tallennus'}
          show={this.state.showModal}
          onHide={this.handleHide}
          buttonStyle={'primary'}
          buttonText={'Sulje'}
          withCancel={false}
        />
      );
    } else if (this.state.showModal && this.state.success) {
      modalContent = (
        <Modal
          message={'Tallennus onnistui'}
          messageStyle={'success'}
          title={'Tilastomerkinnnän tallennus'}
          show={this.state.showModal}
          onHide={this.handleHide}
          buttonStyle={'primary'}
          buttonText={'Palaa tilastointiin'}
        />
      );
    }
    let otsikko = null;
    if (this.props.tapahtumaTaso === 'Organisaatio') {
      otsikko = this.props.yhdistysNimi;
    } else if (this.props.tapahtumaTaso === 'Toimintamuoto') {
      otsikko = this.props.otsikko;
    } else if (this.props.tapahtumaTaso === 'Toimintaryhma') {
      otsikko = this.props.toimintaryhmaNimi;
      }
      let tallennaNappi = null;
      const tallennaEnabled = this.state.tapahtuma.loppupvm !== '' ? true : false;
      if (tallennaEnabled === true) {
          tallennaNappi = (
              <Button bsClass='TallennaRyhma' type='submit'>
                  Tallenna
              </Button>
          )
      } else {
          tallennaNappi = <Button bsClass='TallennaRyhma' type='submit' disabled={true}>
               [Tallenna] -> Puuttuu: Pvm!
              </Button>
      }
    return (
      <Wrapper>
        {this.state.showModal ? modalContent : null}
        <Row className='ToimintamuotoOtsikko'>
            <Col className='col-xs-2'>
               <Button onClick={() => this.toggleGoTo(this.props.tapahtumaTaso)}>
                 <Glyphicon glyph='arrow-left' />
                </Button>
            </Col>
            <Col className='col-xs-8'>
               <h3>{otsikko}</h3>
            </Col>
            <Col className='col-xs-2'>
               <Tooltip
                placement='left'
                location='right'
                ohjetekstinimi='tapahtumanlisays'
                title='Yhdistyksen toimintamuodot'
                content='tähän voi suoltaa infotekstiä :)'
                />
            </Col>
        </Row>
        <Form onSubmit={this.lisaaTapahtuma}>
          <h3 className='text-center'>Lisää tilastoja</h3>
          <LabeledInput
            title='Oma otsikko'
            name='Otsikko'
            type='text'
            value={this.state.tapahtuma.Otsikko}
            placeholder='Otsikko'
            onChange={this.handleTextChange}
            tooltip='tilasto_otsikko'
            maxLength='25' 

          />
          {/* <CommonLabeledNumberPicker
            title='Tapahtuman tyyppi'
            name='Tyyppi'
            type='text'
            value={this.state.tapahtuma.Tyyppi}
            placeholder='Tyyppi'
            onChange={this.handleInputChange}
          /> */}
          <LabeledInput
            title='Miltä ajalta tilastot on? Alkaen Pvm'
            type='date'
            name='alkupvm'
            value={this.state.tapahtuma.alkupvm}
            placeholder='Alkaen pvm'
            onChange={this.handleTextChange}
            tooltip='tilasto_alkupvm'
            maxLength='10'
          />
         <LabeledInput
            title='Päättyen Pvm'
            type='date'
            name='loppupvm'
            value={this.state.tapahtuma.loppupvm}
            placeholder='Päättyen pvm'
            onChange={this.handleTextChange}
            tooltip='tilasto_loppupvm'
            maxLength='10'
          />
          <CommonLabeledNumberPicker
                    title='Toimintaryhmien määrä (jos on)'
                    name='toimintaryhmia'
                    placeholder='toimintaryhmia'
                    value={this.state.tapahtuma.toimintaryhmia}
                    onChange={this.handleInputChange}
                    tooltip='tilasto_tapahtumia'
          />
          <CommonLabeledNumberPicker
                    title='Kokoontumisten / Tapahtumien määrä'
                    name='tapahtumia'
                    placeholder='tapahtumia'
                    value={this.state.tapahtuma.tapahtumia}
                    onChange={this.handleInputChange}
                    tooltip='tilasto_tapahtumia'
          />
          
          <CommonLabeledNumberPicker
            title='Aikuisten käyntikertoja / kohdattuja aikuisia'
            name='Aikuisia'
            placeholder='Aikuisia'
            value={this.state.tapahtuma.Aikuisia}
            onChange={this.handleInputChange}
            tooltip='tilasto_kayntikertoja'
          />
          <CommonLabeledNumberPicker
            title='Lasten käyntikertoja / Kohdattuja lapsia tai nuoria​'
            name='Lapsia'
            placeholder='Lapsia'
            value={this.state.tapahtuma.Lapsia}
            onChange={this.handleInputChange}
            tooltip='tilasto_lastenkayntikertoja'
          />
          <LabeledDecimalPicker
            title='Vapaaehtoisia per kerta keskimäärin'
            name='vapehtperkerta'
            placeholder='vapehtperkerta'
            value={this.state.tapahtuma.vapehtperkerta}
            onChange={this.handleInputChange}
            tooltip='tilasto_vapehtperkerta'
          />
          <LabeledDecimalPicker
            title='Vapaaehtoisten tunteja yhteensä'
            name='vapehttuntejayht'
            placeholder='vapehttuntejayht'
            value={this.state.tapahtuma.vapehttuntejayht}
            onChange={this.handleInputChange}
            tooltip='tilasto_vapehttuntejayht'
           />
     
          <LabeledInput
             title='Lisätietoa'
             name='Lisatietoa'
             type='text'
             value={this.state.tapahtuma.Lisatietoa}
             placeholder='Lisätietoa'
             onChange={this.handleTextChange}
             tooltip='tilasto_lisatietoa'
             maxLength='500'
          />
       
        {tallennaNappi}
          
        </Form>
      </Wrapper>
    );
  }
}
TapahtumanLisays.propTypes = {
  toimintaryhmaId: PropTypes.number,
  OrganisaatioId: PropTypes.number,
  toimintamuototsikotid: PropTypes.number
};

export default TapahtumanLisays;
