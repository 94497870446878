import React, { Component } from 'react';
import { Checkbox } from 'react-bootstrap';
import '../../../styles/indikaattorilomake.css';
import PropTypes from 'prop-types';

class Monivalinta extends Component {
  state = {
    valitut: []
  };

  componentDidMount() {
    const valinnat = [];
    this.props.monivalinnat.forEach(v => {
      valinnat.push(v.vid);
    });
    this.setState({
      valitut: valinnat
    });
  }

  handleBoxClick = event => {
    const valintaId = Number(event.target.id);
    const valinnat = this.state.valitut;
    if (valinnat.includes(valintaId)) {
      for (let i = valinnat.length - 1; i >= 0; i--) {
        if (valinnat[i] === valintaId) {
          valinnat.splice(i, 1);
        }
      }
    } else {
      valinnat.push(valintaId);
    }
    this.setState({ valitut: valinnat });
    const moniValinnat = [];
    valinnat.forEach(valinta =>
      moniValinnat.push({
        smiid: this.props.ohjausmerkinta.id,
        vid: valinta,
        arvo: true
      })
    );
    this.props.ohjausmerkinta.monivalintavastaukset = moniValinnat;
    this.props.lisaaVastaus(this.props.ohjausmerkinta);
  };

  render() {
    const Valinnat = this.props.ohjausmerkinta.monivalinnat.map(mv => {
      let checked = false;
      this.state.valitut.forEach(v => {
        if (v === mv.id) {
          checked = true;
        }
      });
        return (
        <Checkbox
          onChange={this.handleBoxClick}
          id={mv.id}
          key={mv.id}
          checked={checked}
        >
          {mv.teksti}
         </Checkbox>
      );
    });
    return (
      <div className='OhjausKysymysWrapperi'>
        <b>{this.props.ohjausmerkinta.kuvaus}</b>
        <p>{this.props.ohjausmerkinta.tarkennukset}</p>
        {Valinnat}
      </div>
    );
  }
}

Monivalinta.propTypes = {
  ohjausmerkinta: PropTypes.object,
  lisaaVastaus: PropTypes.func.isRequired
};

export default Monivalinta;
