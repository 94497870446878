import React, { Component } from 'react';
import { Row, Button } from 'react-bootstrap';
import '../../../styles/indikaattorilomake.css';
import PropTypes from 'prop-types';

class Vaittama extends Component {
  state = {
    valittu: this.props.vastaus
  };

  handleButtonClick = event => {
    const valittu = Number(event.target.id);
      if (this.state.valittu === valittu) {
          this.setState({ valittu: null });
          this.props.ohjausmerkinta.vastaus = null;
      } else {
          this.setState({ valittu });
          this.props.ohjausmerkinta.vastaus = valittu;
      }
    this.render();
    this.props.lisaaVastaus(this.props.ohjausmerkinta);
  };

  render() {
    return (
      <div className='OhjausKysymysWrapperi'>
        <b>{this.props.ohjausmerkinta.kuvaus}</b>
        <p>{this.props.ohjausmerkinta.tarkennukset}</p>
        <Row>
          {this.state.valittu === 1 ? (
            <Button
              id='1'
              className='VaittamaNappiValittu'
              bsSize='sm'
              bsStyle='danger'
              onClick={this.handleButtonClick}
            >
              1
            </Button>
          ) : (
            <Button
              id='1'
              className='VaittamaNappi'
              bsSize='sm'
              bsStyle='danger'
              onClick={this.handleButtonClick}
            >
              1
            </Button>
          )}
          {this.state.valittu === 2 ? (
            <Button
              id='2'
              className='VaittamaNappiValittu'
              bsSize='sm'
              bsStyle='warning'
              onClick={this.handleButtonClick}
            >
              2
            </Button>
          ) : (
            <Button
              id='2'
              className='VaittamaNappi'
              bsSize='sm'
              bsStyle='warning'
              onClick={this.handleButtonClick}
            >
              2
            </Button>
          )}
          {this.state.valittu === 3 ? (
            <Button
              id='3'
              className='VaittamaNappiValittu'
              bsSize='sm'
              bsStyle='success'
              onClick={this.handleButtonClick}
            >
              3
            </Button>
          ) : (
            <Button
              id='3'
              className='VaittamaNappi'
              bsSize='sm'
              bsStyle='success'
              onClick={this.handleButtonClick}
            >
              3
            </Button>
          )}
        </Row>
      </div>
    );
  }
}

Vaittama.propTypes = {
  ohjausmerkinta: PropTypes.object,
  lisaaVastaus: PropTypes.func.isRequired
};

export default Vaittama;
