import React, { Component } from 'react';
import {
  Row,
  MenuItem,
  DropdownButton,
  Button,
  Glyphicon,
  Col
} from 'react-bootstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import Select from 'react-select';
import * as R from 'ramda';
import '../../../../styles/seurantamerkintalomake.css';
import Wrapper from '../../../../hoc/wrapper/Wrapper';
import IndikaattoriLomake from './IndikaattoriLomake/IndikaattoriLomake';
import TilastoLomakeEditori from './TilastoLomake/TilastoLomakeEditori';
import Yhdistysvalinta from './Yhdistysvalinta/Yhdistysvalinta';
import { apiBaseUrl, authString } from '../../../../urlPaths';
import LabeledInput from '../../../../hoc/FormComponents/LabeledInput';
import Tooltip from '../../../../hoc/FormComponents/Tooltip';
import Modal from '../../../../hoc/FormComponents/Modal';
import '../../../../styles/yhdistysvalinta.css';
import moment from 'moment';


class SeurantaMerkintaEdit extends Component {
  state = {
    selectedosaalue: null,
    loadingseurantamerkinta: true,
    loadingorganisaatiot: true,
    loadingotsikot: true,
    loadingosaalueet: true,
    error: false,
    errormessage: null,
    show: false,
    success: false,
    seurantamerkinta: {
      aikuisia: 0,
      id: 0,
      kayttajaid: this.props.kayttajaid,
      kirjauspvm: '',
      kysyttavat: 0,
      lapsia: 0,
      lisatietoa: '',
      luokka: '',
      luokkaid: this.props.luokkaid,
      muutosaika: '',
      omaotsikko: '',
      organsations: [],
      osaalue: '',
      osaalueid: 1,
      pvm: moment(Date()).format('YYYY-MM-DD'),
      seurantamerkintaindikaattorit: [],
      seurantamerkintaotsikotid: 0,
      toimintamuoto: 0,
      tyoaikaa: 0,
      vapaaehtoisia: 0,
      vapehttuntejaperkerta: 0,
      yhdistyslkm: 0
    }, // Tämä sisältää seurantamerkinnän tiedot
    naytatilastot: false,
    naytaindikaattorit: false,
    otsikot: [],
    otsikko: '',
    valitutyhdistykset: [],
    naytayhdistysvalinta: false,
    osaalueet: null
  };

  componentDidMount() {
    if (this.props.seurantamerkintaid !== 0) {
      this.haeSeurantamerkinta(this.props.seurantamerkintaid);
      this.haeOtsikot(false);
    } else {
      this.haeOsaAlueet();
      this.haeOtsikot(true);
    }
  }

  haeOsaAlueet() {
    axios
      .get(`${apiBaseUrl}HaeOsaAlueet`, {
          headers: {
              Authorization: authString(),
              'Content-Type': 'application/json'
          }
      })
      .then(response => {
        this.setState({ osaalueet: response.data, loadingosaalueet: false });
      })
      .catch(error => {
        console.log(error);
      });
  }

  haeOtsikot(merkkaaladatuksi) {
    axios
        .get(`${apiBaseUrl}HaeSeurantaMerkintaOtsikot`, {
            headers: {
                Authorization: authString(),
                'Content-Type': 'application/json'
            }
      })
      .then(response => {
        if (merkkaaladatuksi === false) {
          // Lataukset merkitään pois latausfunkkareissa
          this.setState({ otsikot: response.data, loadingotsikot: false });
        } else {
          // Uutta tehdessä merkitään kaikki ladatuiksi täällä jotta rendaa
          this.setState({
            otsikot: response.data,
            loadingotsikot: false,
            loadingseurantamerkinta: false,
            loadingorganisaatiot: false
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  haeSeurantamerkinnanOrganisaatiot() {
    const seurantamerkintaid = this.state.seurantamerkinta.id;
    axios
      .get(`${apiBaseUrl}HaeSeurantamerkinnanOrganisaatiot`, {
        headers: {
          Authorization: authString(),
         'Content-Type': 'application/json',
          seurantamerkintaid
        }
      })
      .then(response => {
        this.setState({
          valitutyhdistykset: response.data,
          loadingorganisaatiot: false
        });
        this.haeOsaAlueet();
      })
      .catch(error => {
        console.log(error);
      });
    //this.setState({ loadingOrganisaatiot: true });
  }

  haeOtsikko = event => {
    const otsikot = this.state.otsikot;
    const objekti = R.find(R.propEq('id', event))(otsikot);
    this.handleInputChange('seurantamerkintaotsikotid', objekti.id);
    this.handleTitleChange('otsikko', objekti.otsikko);
  };

  haeSeurantamerkinta(id) {
    axios
      .get(`${apiBaseUrl}HaeSeurantamerkinta`, {
        headers: {
          Authorization: authString(),
          id
        }
      })
      .then(response => {
        const valittuOSAalue = Object.create({
          value: response.data.osaalueid,
          label: response.data.osaalue
        });
        this.setState({
          seurantamerkinta: response.data,
          loadingseurantamerkinta: false,
          selectedosaalue: valittuOSAalue,
          otsikko: response.data.seurantamerkintaotsikko
        });
        this.haeSeurantamerkinnanOrganisaatiot();
      })
      .catch(error => {
        console.log(error);
      });
    // this.setState({ loading: true });
  }

  handleButtonClick = event => {
    if (event.target.id === 'tilastot') {
      this.setState({ naytatilastot: !this.state.naytatilastot });
    } else if (event.target.id === 'indikaattorit') {
      this.setState({ naytaindikaattorit: !this.state.naytaindikaattorit });
    } else if (event.target.id === 'yhdistysvalinta') {
      this.setState({ naytayhdistysvalinta: !this.state.naytayhdistysvalinta });
    }
  };

  kasittelePvm = event => {
    this.setState({
      pvm: event.target.value
    });
  };

  lisaaIndikaattorit = indikaattorit => {
    this.setState({ indikaattorit });
  };

  lisaaYhdistys = yhdistys => {
    const valitut = this.state.valitutyhdistykset;
    const seurantamerkinta = this.state.seurantamerkinta;
    const yhdistykset = [];
    if (!this.state.valitutyhdistykset.includes(yhdistys)) {
      valitut.push(yhdistys);
      valitut.forEach(yhd => yhdistykset.push(yhd.id));
      seurantamerkinta.organsations = yhdistykset;
      seurantamerkinta.yhdistyslkm = seurantamerkinta.yhdistyslkm + 1;
      this.setState({
        valitutyhdistykset: valitut,
        seurantamerkinta
      });
    }
  };

  poistaYhdistys = yhdistysId => {
    const organisaatiot = this.state.valitutyhdistykset;
    const seurantamerkinta = this.state.seurantamerkinta;
    const yhdistys = this.state.valitutyhdistykset.filter(
      yhd => yhd.id === yhdistysId
    );
    const yhdistykset = [];
    const index = organisaatiot.indexOf(yhdistys[0]);
    if (index > -1) {
      organisaatiot.splice(index, 1);
    }
    organisaatiot.forEach(yhd => yhdistykset.push(yhd.id));
    seurantamerkinta.organsations = yhdistykset;
    seurantamerkinta.yhdistyslkm = seurantamerkinta.yhdistyslkm - 1;
    this.setState({
      valitutyhdistykset: organisaatiot,
      seurantamerkinta
    });
  };

  handleTitleChange = (titlename, value) => {
    const name = titlename;
    this.setState({
      [name]: value
    });
  };

  handleInputChange = (updateobjectname, value) => {
    const seurantamerkintauusi = { ...this.state.seurantamerkinta };
    seurantamerkintauusi[updateobjectname] = value;
    this.setState({ seurantamerkinta: seurantamerkintauusi });
  };

  handleSubInputChange = (master, sub, value) => {
    const seurantamerkintauusi = { ...this.state.seurantamerkinta };
    const masterObject = seurantamerkintauusi[master];
    masterObject[sub] = value;
    seurantamerkintauusi[master] = masterObject;
    this.setState({ seurantamerkinta: seurantamerkintauusi });
  };

  handleIndikaattoritChange = indikaattorit => {
    const seurantamerkintauusi = { ...this.state.seurantamerkinta };
    seurantamerkintauusi.seurantamerkintaindikaattorit = indikaattorit;
    this.setState({
      seurantamerkinta: seurantamerkintauusi
    });
  };

  handleTextChange = e => {
    const target = e.target;
    const kentta = target.attributes.name.nodeValue;
    const seurantamerkinta = this.state.seurantamerkinta;
    seurantamerkinta[kentta] = target.value;
    this.setState({ seurantamerkinta });
  };

  lahetaVastaukset = () => {
    if (
      (this.state.seurantamerkinta.kirjauspvm !== '',
      this.state.seurantamerkinta.seurantamerkintaotsikotid !== 0)
    ) {
      const obj = this.state.seurantamerkinta;
      if (this.state.selectedosaalue !== null) {
        obj.osaalue = this.state.selectedosaalue.label;
        obj.osaalueid = this.state.selectedosaalue.value;
      }
      if (obj.seurantamerkintaindikaattorit !== null) {
        obj.seurantamerkintaindikaattorit.forEach((part, index) => {
          part.ohjausindikaattori = {};
          part.seurantamerkinta = {};
        });
      }

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authString()
      };
      const org = []; // Tähän array org idistä.
      const body = { obj, org };
      axios
        .post(`${apiBaseUrl}TallennaSeurantamerkinta`, obj, { headers })
        .then(response => {
          this.setState({
            show: true,
            success: true
          });
        })
        .catch(error => {
          this.setState({
            error: true,
            errorMessage: error.message,
            show: true
          });
        });
    } else {
      this.setState({
        error: true,
        errorMessage: 'Täytä vähintään Kirjauspäivämäärä ja joku indikaattori'
      });
      this.showModal();
    }
  };

  showModal = () => {
    this.setState({ show: true });
  };

  handleOsaAlueChange = selectedosaalue => {

        this.setState({ selectedosaalue });
  };

  handleHide = () => {
    if (this.state.error) {
      this.setState({
        show: false,
        error: false,
        errorMessage: null,
        success: false
      });
    } else {
        this.setState({ show: false }, () => {
            switch (this.state.seurantamerkinta.luokkaid) {
                case 1:
                    this.props.handlenakyma('yhdistys');
                    break;
                case 2:
                    this.props.handlenakyma('perhekahvilat');
                    break;
                case 3:
                    this.props.handlenakyma('kunnat');
                    break;
            }
      });
    }
  };

  render() {
    if (this.state.loadingseurantamerkinta === true) {
      return null;
    }
    if (this.state.loadingorganisaatiot === true) {
      return null;
    }
    if (this.state.loadingotsikot === true) {
      return null;
    }
    if (this.state.loadingosaalueet === true) {
      return null;
    }
    const options = [];
    const selOsaAlue = this.state.selectedosaalue;
    this.state.osaalueet.forEach(data => {
      const value = data.id;
      const label = data.osaalue;
      options.push({
        value: value,
        label: label
      });
    });

    let tallennusactive = false;
    if (this.state.otsikko !== '' && this.state.seurantamerkinta.pvm !== "" && this.state.valitutyhdistykset.length > 0) tallennusactive = true;
    let modalContent = null;
    if (this.state.show && this.state.error) {
      modalContent = (
        <Modal
          message={
            'Tallennus epäonnistui!'
          }
          details={this.state.errormessage}
          messageStyle={'danger'}
          title={'Seurantamerkinnän tallennus'}
          show={this.state.show}
          onHide={this.handleHide}
          buttonStyle={'primary'}
          buttonText={'Sulje'}
          withCancel={false}
        />
      );
    } else if (this.state.show && this.state.success) {
      modalContent = (
        <Modal
          message={'Tallennus onnistui'}
          messageStyle={'success'}
          title={'Seurantamerkinnän tallennus'}
          show={this.state.show}
          onHide={this.handleHide}
          buttonStyle={'primary'}
          buttonText={'Palaa etusivulle'}
        />
      );
    }

    let puoli = null;
    switch (this.state.seurantamerkinta.luokkaid) {
        case 1: {
            puoli = 'yhdistys';
            break;
        }
        case 2: {
            puoli = 'perhekahvilat';
            break;
        }
        case 3: {
            puoli = 'kunnat';
            break;
        }

    }
    
    const Otsikot = this.state.otsikot.map(otsikko => (
      <MenuItem
        key={otsikko.id}
        eventKey={otsikko.id}
        onSelect={this.haeOtsikko}
      >
        {otsikko.otsikko}
      </MenuItem>
    ));
    const OtsikonValinta = (
      <DropdownButton
        id='otsikkodd'
        bsSize='large'
        title={this.state.otsikko || 'Otsikko'}
      >
        {Otsikot}
      </DropdownButton>
    );

    const oValitutYhdistykset = this.state.valitutyhdistykset.map(yhdistys => (
      <Row key={yhdistys.id}>
        <strong>{yhdistys.lyhytNimi}</strong>
      </Row>
    ));

    let minka = '';
    switch (this.props.luokkaid) {
          case 1: {
            minka = 'yhdistyksen';
            break;
          }
          case 2: {
            minka = 'perhekahvilan';
            break;
          }
          case 3: {
            minka = ''; //Välisana pois kunnan tapauksessa. #95 Juuso
            break;
          }

      }
    let moodi = 'Muokkaa';
    let moodi2 = 'ohjausmerkintää';
    if (this.state.seurantamerkinta.id === 0) {
      moodi = 'Lisää';
      moodi2 = 'ohjausmerkintä';
    }
    let infoteksti = moodi+' '+minka+' '+moodi2;
    
    const tooltip = (
      <Tooltip
        placement='left'
        title='Seurantamerkintälomake'
        ohjetekstinimi='smerkintalomake'
      />
    );
    const tilastolomakeeditori = (
      <div className='ValintaWrapperi'>
        <Select
          value={selOsaAlue}
          onChange={this.handleOsaAlueChange}
          options={options}
          placeholder='Valitse osa-alue...'
        />
        <TilastoLomakeEditori
          key={1}
          // tilastot={this.state.tilastot}
          handleInputChange={this.handleInputChange}
          luokkaId={this.props.luokkaid}
          vapaaehtoisia={this.state.seurantamerkinta.vapaaehtoisia}
          aikuisia={this.state.seurantamerkinta.aikuisia}
          lapsia={this.state.seurantamerkinta.lapsia}
          vapEhtTunteja={this.state.seurantamerkinta.vapehttuntejaperkerta}
          tyoaikaa={this.state.seurantamerkinta.tyoaikaa}
        />
      </div>
    );
    return (
      <Wrapper>
        {this.state.show ? modalContent : null}
        <Row style={{ paddingBottom: '30px' }}>
          <Col className='col-xs-2'>
            <Button onClick={() => this.props.handlenakyma(puoli)}>
              <Glyphicon glyph='arrow-left' />
            </Button>
          </Col>
          <Col className='col-xs-8'>
            <h2>{this.props.piirinimi}</h2>
          </Col>
          <Col className='col-xs-2'>{tooltip}</Col>
        </Row>
          <div>
            <h2>
              {infoteksti}
            </h2>
        </div>
        <div className='KysymysWrapperi'>
          {OtsikonValinta}
          <LabeledInput
            type='text'
            title='Otsikon lisätieto'
            value={this.state.seurantamerkinta.omaotsikko}
            name='omaotsikko'
            onChange={this.handleTextChange}
            className='OmaOtsikkoKentta'
            placeholder='Otsikon lisätieto'
            maxLength='50'

          />
          <LabeledInput
            type='date'
            title='Pvm'
            placeholder='Päivämäärä'
            name='pvm'
            onChange={this.handleTextChange}
            value={this.state.seurantamerkinta.pvm}
            maxLength='10'
          />
        </div>

        {this.state.naytayhdistysvalinta ? (
          <div>
            <Yhdistysvalinta
                        valitutyhdistykset={this.state.valitutyhdistykset}
                        lisaaYhdistys={this.lisaaYhdistys}
                        poistaYhdistys={this.poistaYhdistys}
                        yhdistysvalintaClick={this.handleButtonClick}
                        kayttajatyyppi={this.props.kayttajatyyppi}
                        piiriid={this.props.piiriid}
                        luokkaid={this.props.luokkaid}  // 1= Yhdistys, 2=Kahvila, 3=Kunta
            />
            <Button
              id='yhdistysvalinta'
              bsStyle='success'
              onClick={this.handleButtonClick}
            >
              Valmis
            </Button>
          </div>
        ) : (
          <div>
            <div className='KysymysWrapperi'>
              <button
                id='yhdistysvalinta'
                className='YhdistysNappi'
                onClick={this.handleButtonClick}
              >
                 Valitse {this.props.luokkaid == 3 ? 'kunnat' : 'yhdistykset'}
              </button>
              {oValitutYhdistykset}
            </div>
            <div>
              <button
                id='tilastot'
                className='SininenNappi'
                onClick={this.handleButtonClick}
              >
                Tilastot
              </button>
              {this.state.naytatilastot ? tilastolomakeeditori : null}
              <button
                id='indikaattorit'
                className='SininenNappi'
                onClick={this.handleButtonClick}
              >
                Indikaattorit
              </button>
              {this.state.naytaindikaattorit ? (
                <IndikaattoriLomake
                  lisaaIndikaattorit={this.lisaaIndikaattorit}
                  luokkaId={this.props.luokkaid}
                  handleIndikaattoritChange={this.handleIndikaattoritChange}
                  indikaattorit={
                    this.state.seurantamerkinta.seurantamerkintaindikaattorit
                  }
                  smedit={this.props.smedit}
                />
              ) : null}
            </div>
          {tallennusactive === true ? (
            <Button bsClass='TallennaNappi' onClick={this.lahetaVastaukset} >
                 Tallenna
            </Button>) : (
            <Button bsClass='TallennaNappi' disabled >
                Valitse Otsikko, Pvm ja Yhdistys!
            </Button>
            )
          }
          </div>
        )}
      </Wrapper>
    );
  }
}

SeurantaMerkintaEdit.propTypes = {
  luokkaid: PropTypes.number,
  seurantamerkintaid: PropTypes.number,
  kayttajaid: PropTypes.number,
  handlenakyma: PropTypes.func.isRequired,
  piirinimi: PropTypes.string,
  smedit: PropTypes.bool
};

export default SeurantaMerkintaEdit;
