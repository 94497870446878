import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../../../../hoc/wrapper/Wrapper';
import { Row, Glyphicon, Col, Button } from 'react-bootstrap';
import '../../../../styles/yhdistys.css';
import '../../../../styles/linkkinappi.css';
import TapahtumienSelausLista from './TapahtumaLista/TapahtumienSelausLista';
import Tooltip from '../../../../hoc/FormComponents/Tooltip';

class Tapahtuma extends Component {
  state = {
      modify: false
  };

  toggleGoTo = taso => {
    switch (taso) {
      case 'Organisaatio':
        this.props.handleTapahtumaTasoAndGoTo(taso, 'Yhdistys');
        break;
      case 'Toimintamuoto':
        this.props.handleTapahtumaTasoAndGoTo(taso, 'Toimintamuoto');
        break;
      case 'Toimintaryhma':
        this.props.handleTapahtumaTasoAndGoTo(taso, 'Toimintamuoto');
        break;
      default:
        return null;
    }
  };

  handleEditClick = () => {
    if (!this.state.modify) {
      this.setState({ modify: true });
    } else {
      this.setState({ modify: false });
    }
  };

  render() {
    let otsikko = null;
    if (this.props.tapahtumaTaso === 'Organisaatio') {
      otsikko = this.props.yhdistysNimi;
    } else if (this.props.tapahtumaTaso === 'Toimintamuoto') {
      otsikko = this.props.otsikko;
    } else if (this.props.tapahtumaTaso === 'Toimintaryhma') {
      otsikko = this.props.toimintaryhmaNimi;
    }

    return (
      <Wrapper>
        <Row className='ToimintamuotoOtsikko'>
          <Col className='col-xs-2'>
            <Button onClick={() => this.toggleGoTo(this.props.tapahtumaTaso)}>
               <Glyphicon glyph='arrow-left' />
            </Button>
          </Col>
          <Col className='col-xs-8'>
            <h3>{otsikko}</h3>
          </Col>
          <Col className='col-xs-2'>
            <Tooltip placement='left' title='Tapahtuma' ohjetekstinimi='tapahtuma' />
          </Col>
        </Row>
        <Row>
          <Col className='col-xs-6'>
            <Button bsClass='LinkkiNappiMatala' onClick={() => this.props.goTo('TapahtumanLisays')}>
              Lisää tilastot
            </Button>
          </Col>
          <Col className='col-xs-6' onClick={this.handleEditClick}>
            <Button>
              {this.state.modify ? <Glyphicon glyph='ok' /> : <Glyphicon glyph='pencil' />}
            </Button>
          </Col>
        </Row>
        <TapahtumienSelausLista
          tapahtumaTaso={this.props.tapahtumaTaso}
          organisaatioId={this.props.organisaatioId}
          toimintaryhmaId={this.props.toimintaryhmaId}
          toimintamuotoId={this.props.toimintamuotoId}
          handleTapahtumaTasoAndGoTo={this.props.handleTapahtumaTasoAndGoTo}
          modify={this.state.modify}
          handleTapahtumaId={this.props.handleTapahtumaId}
          goTo={this.props.goTo}
        />
      </Wrapper>
    );
  }
}

Tapahtuma.propTypes = {
  otsikko: PropTypes.string,
  goTo: PropTypes.func
};

export default Tapahtuma;
