import React, { Component } from 'react';
import '../../../styles/indikaattorilomake.css';
import PropTypes from 'prop-types';

class Lisatieto extends Component {
  state = {
    teksti: ''
  };

  tekstiMuuttui = event => {
    this.setState({ teksti: event.target.value });
  };

  liitaVastaus = () => {
    this.props.ohjausmerkinta.vastaus = this.state.teksti;
    this.props.lisaaVastaus(this.props.ohjausmerkinta);
  };

  render() {
    return (
      <div className='OhjausKysymysWrapperi'>
        <b>{this.props.ohjausmerkinta.kuvaus}</b>
        <p>{this.props.ohjausmerkinta.tarkennukset}</p>
         <textarea
          maxLength="255"
          className='TekstiKentta'
          onBlur={this.liitaVastaus}
          onChange={this.tekstiMuuttui}
          defaultValue={this.props.vastaus}
        />
      </div>
    );
  }
}

Lisatieto.propTypes = {
  lisaaVastaus: PropTypes.func.isRequired,
  ohjausmerkinta: PropTypes.object
};

export default Lisatieto;
