import React from 'react';
import { ControlLabel, FormControl, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import Tooltip from '../FormComponents/Tooltip';

const LabeledInput = props => {
  const handleStyle = props => {
    let style = null;
    if (props.type === 'text') {
      style = {
        margin: '15px',
        borderTop: 'solid gray',
        padding: '15px'
      };
    } else if (props.type === 'date') {
      style = {
        margin: '15px auto',
        borderTop: 'solid gray',
        padding: '15px',
        width: '300px',
        textAlign: 'center'
      };
    }
    return style;
  };

  const handleDate = value => moment(value).format('YYYY-MM-DD');
  
  const formcontrol = (
      <FormControl
          type={props.type}
          maxLength={props.maxLength}
          placeholder={props.placeholder}
          name={props.name}
          onBlur={props.onBlur}
          onChange={props.onChange}
          value={props.type === 'date' ? handleDate(props.value) : props.value}
      />
  );

  // If we  send tooltip here, let's build it. 
 if (typeof(props.tooltip) != 'undefined') {
     return (
         
         <Row style={handleStyle(props)}>
             <Col className='col-xs-12'>
                 <Row>
                     <Col className='col-xs-12'>
                         <ControlLabel>{props.title}</ControlLabel>
                     </Col>
                 </Row>
                 <Row>
                 <Col className='col-xs-2'>
                 <Tooltip
                     placement='right'
                     location='right'
                     ohjetekstinimi={props.tooltip}
                     title={props.title}
                     content='t�h�n pit�isi lis�t� infoteksti� :)'
                     
                 />
                 </Col>
                 <Col className='col-xs-10'> 
                   {formcontrol}
                 </Col>
                 </Row>
             </Col>
         </Row>
         
      );
  }


  return (
    <Row style={handleStyle(props)}>
      <ControlLabel>{props.title}</ControlLabel>
      {formcontrol}
    </Row>
  );
};

LabeledInput.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  tooltip: PropTypes.string,
  maxLength: PropTypes.string,
};

export default LabeledInput;
