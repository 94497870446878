import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Glyphicon, Button, Row, Col, Panel } from 'react-bootstrap';
import axios from 'axios';
import '../../styles/yhdistys.css';
import { apiBaseUrl, authString } from '../../urlPaths';
import ProgressBar from '../../hoc/FormComponents/Progressbar';
import AlertMessage from '../../hoc/FormComponents/AlertMessage';
import ToimintamuotoLista from './ToimintamuotoLista/ToimintamuotoLista';
import Modal from '../../hoc/FormComponents/Modal';
import Tooltip from '../../hoc/FormComponents/Tooltip';

class Toimintamuodot extends Component {
  state = {
    loading: false,
    error: false,
    modify: false,
    errorMessage: null,
    showModal: false,
    Pvm_loppu: ''
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.haeToimintaMuotoOtsikot();
  }

  handleEditClick = () => {
    if (!this.state.modify) {
      this.setState({ modify: true });
    } else {
      this.setState({ modify: false });
    }
  };

  handlePvmChange = e => {
    this.setState({ Pvm_loppu: e.target.value });
  };

  handleInputChange = event => {
    const target = event.target;
    const name = target.dateFieldName;
    const value = name.dateValue;
    this.setState({
      [name]: value
    });
  };

  haeToimintaMuotoOtsikot = () => {
    axios
      .get(`${apiBaseUrl}HaeOrganisaationToimintaMuotoOtsikot`, {
        headers: {
          organisaatioid: this.props.organisaatioId,
          Authorization: authString()
        }
      })
      .then(response => {
        const otsikot = response.data;
        const rajatutArvot = [];
        if (this.props.overrideMode === false) {
          if (this.props.kayttajaTyyppi === 1) {
            otsikot.forEach(data => {
              if (data.yhdistyksen === true) {
                rajatutArvot.push(data);
              }
            });
          }
          if (this.props.kayttajaTyyppi !== 1) {
            otsikot.forEach(data => {
              if (data.piirin === true) {
                rajatutArvot.push(data);
              }
            });
          }
        } else {
          otsikot.forEach(data => {
            if (data.yhdistyksen === true) {
              rajatutArvot.push(data);
            }
          });
        }
        this.setState({
          loading: false
        });
        this.props.handleArrayData(rajatutArvot);
        console.log(response.data);
      })
      .catch(error => {
        this.setState({
          error: true,
          loading: false,
          errorMessage: error.message
        });
        console.log(`error${error}`);
      });
  };

  showModal = toimintamuotoId => {
    this.setState({ showModal: true, toimintamuotoId });
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleRemove = () => {
    this.lopetaToimintamuoto();
    this.setState(
      {
        showModal: false
      },
      () => {
        this.props.goTo('Yhdistys');
      }
    );
  };

  lopetaToimintamuoto = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: authString(),
      organisaatioId: this.props.organisaatioId,
      toimintamuotoId: this.props.toimintamuotoId,
      pvmLoppu: this.state.Pvm_loppu
    };
    axios
      .post(`${apiBaseUrl}PoistaToimintaMuotoKaytosta`, null, {
        headers
      })
      .then(response => {
        const responseData = response.data;
        this.haeToimintaMuotoOtsikot();
        console.log(responseData);
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const modal = (
      <Modal
        message={`Olet lopettamassa toimintamuodon ${
          this.props.otsikko
        }. Syötä ajankohta, milloin toiminta loppui ja valitse lopeta. Jos toiminnalle on syötetty tilastoja, ne säilyvät tallessa, mutta et pääse enää muokkaamaan niitä.`}
        messageStyle={'danger'}
        title={'Toimintamuodon lopetus'}
        show={this.state.showModal}
        onHide={this.handleRemove}
        buttonStyle={'danger'}
        buttonText={'Lopeta'}
        withCancel={true}
        onCancel={this.handleCancel}
        askEndDate={true}
        onChange={this.handlePvmChange}
        name={'Pvm_loppu'}
        value={this.state.Pvm_loppu}
      />
    );
    let Toimintamuotootsikot = null;
    const otsikot = this.props.toimintamuotootsikot;
    if (this.state.error) {
      Toimintamuotootsikot = (
        <AlertMessage
          alertStyle='danger'
          message={
            'Haussa tapahtui virhe. Käynnistä sovellus uudelleen MLL logosta.'
          }
          details={this.state.errorMessage}
        />
      );
    } else if (this.state.loading) {
      Toimintamuotootsikot = (
        <Panel>
          <h4>Haetaan...</h4>
          <ProgressBar />
        </Panel>
      );
    } else {
      Toimintamuotootsikot = (
        <div className='ToimintamuotoOtsikko'>
          <ToimintamuotoLista
            toimintamuotootsikot={otsikot}
            modify={this.state.modify}
            goTo={this.props.goTo}
            handleData={this.props.handleData}
            handleDataAndGoTo={this.props.handleDataAndGoTo}
            showModal={this.showModal}
          />
        </div>
      );
    }
    let organisaatioid = null;
    if (this.props.debug === true) {
      organisaatioid = 'Org:' + this.props.organisaatioId;
    }
    const tapahtumat = null; /*(
       <Col className='col-xs-4'>
       <Button
          onClick={() =>
          this.props.handleTapahtumaTasoAndGoTo(
         'Organisaatio',
        'Tapahtuma'
         )}
       >
          Tapahtumat
       </Button>
       </Col>
       );*/
    return (
      <div>
        {this.state.showModal ? modal : null}
        <Row className='ToimintamuotoOtsikko'>
          <Col className='col-xs-2'>
            <Button onClick={() => this.props.goTo('Home')}>
              <Glyphicon glyph='arrow-left' />
            </Button>
          </Col>
          <Col className='col-xs-8'>
            <h3>
              {organisaatioid} {this.props.yhdistysNimi}
            </h3>
          </Col>
          <Col className='col-xs-2'>
            <Tooltip
              placement='left'
              title='Yhdistyksen toimintamuodot'
              ohjetekstinimi='toimintamuodot'
            />
          </Col>
        </Row>
        <Row>
          {tapahtumat}
          <Col className='col-xs-8'>
            <h3 className='text-center'>Toimintamuodot</h3>
          </Col>
          <Col className='col-xs-4' onClick={this.handleEditClick}>
            <Button>
              {this.state.modify ? (
                <Glyphicon glyph='ok' />
              ) : (
                <Glyphicon glyph='pencil' />
              )}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className='col-xs-1' />
          <Col className='col-xs-10'>
            <hr className='Vaakaviiva' />
          </Col>
          <Col className='col-xs-1' />
        </Row>
        {Toimintamuotootsikot}
      </div>
    );
  }
}

export default Toimintamuodot;

Toimintamuodot.propTypes = {
  handleDataAndGoTo: PropTypes.func.isRequired,
  toimintamuotootsikot: PropTypes.array.isRequired,
  handleArrayData: PropTypes.func.isRequired,
  kayttajaTyyppi: PropTypes.number.isRequired,
  overrideMode: PropTypes.bool
};
