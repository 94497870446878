import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  OverlayTrigger,
  Button,
  Popover,
  Glyphicon,
  Row,
  Col
} from 'react-bootstrap';
import axios from 'axios';
import { apiBaseUrl, authString } from '../../urlPaths';

class Tooltip extends Component {
  state = {
    ohjeteksti: null
  };

  componentDidMount() {
    if (this.state.ohjeteksti === null) {
      this.getOhjeteksti();
    }
  }

  getOhjeteksti() {
    axios
      .get(`${apiBaseUrl}HaeOhjeTekstiNimella`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authString(),
          idteksti: this.props.ohjetekstinimi
        },
        crossdomain: true
      })
        .then(response => {
        const ohjetekstiData = response.data !== null ? response.data : 'Ohjetta ' + this.props.ohjetekstinimi + 'ei löytynyt';
        this.setState({ ohjeteksti: ohjetekstiData });
        console.log(ohjetekstiData);
      })
      .catch(error => {
        this.setState({
          error: true,
          errorMessage: error.message
        });
      });
  };

    render() {

    if (this.state.ohjeteksti === null) return (null);
    // Etusivulle laatikkona, muuten Popover.
    if (this.props.naytalaatikkonasivulla === true) {
      return (
        <div>
          <Row>
            <Col className='col-xs-12'>
              <h4>{this.state.ohjeteksti}</h4>
            </Col>
          </Row>
        </div>
      );
    }
    // Popover - muutsivut
    return (
      <OverlayTrigger
        style={{ margin: '15px' }}
        trigger='click'
        placement={this.props.placement}
        overlay={
          <Popover
            id={`popover-positioned-${this.props.placement}`}
            title={this.props.title}
          >
            {this.state.ohjeteksti}
          </Popover>
        }
      >
        <Button>
          <Glyphicon glyph='info-sign' />
        </Button>
      </OverlayTrigger>
    );
  }
}

Tooltip.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string.isRequired,
  ohjetekstinimi: PropTypes.string.isRequired,
  naytalaatikkonasivulla: PropTypes.bool
};

export default Tooltip;
