import React, { Component } from 'react';
import { Grid, Row } from 'react-bootstrap';
import TitleBar from '../titlebar/TitleBar';

export class Layout extends Component {
  displayName = Layout.name;

  render() {
    return (
      <Grid fluid>
        <Row>
          <TitleBar />
        </Row>
        <Row>{this.props.children}</Row>
      </Grid>
    );
  }
}
