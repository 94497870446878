import React, { Component } from 'react';
import Select from 'react-select';
import axios from 'axios';
import PropTypes from 'prop-types';
import { apiBaseUrl, authString } from '../../urlPaths';
import '../../styles/yhdistysvalinta.css';
class Yhdistykset extends Component {
    state = {
    selectedOption: null,
    yhdistykset: [],
    yhdistysHaku: '',
    };

    componentDidMount() {
        if (this.props.kayttajaTyyppi === 2) {
            this.noudaYhdistykset();
        } else
            if (this.props.kayttajaTyyppi === 3) {
                this.noudaKaikkiYhdistykset();
        }
    }


  // Yhdistysten haku Piiri - k�ytt�j�lle
  noudaYhdistykset = () => {
     const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authString(),
        };
        const piiriid = this.props.piiriId;
     axios
     .post(`${apiBaseUrl}HaePiirinOrganisaatiot`, piiriid , {
        headers
      })
      .then(response => {
        this.setState({ yhdistykset: response.data });
      })
      .catch(error => {
        console.log(error);
      });
    }

  // Kaikkien yhdistysten haku MLL - k�ytt�j�lle
  noudaKaikkiYhdistykset = () => {
  const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authString(),
        };
        axios
            .get(`${apiBaseUrl}HaeKaikkiOrganisaatiot`,  {
                headers
            })
            .then(response => {
                this.setState({ yhdistykset: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }

  handleChange = selectedOption => {
      this.setState({ selectedOption });
      this.props.yhdistysOverride(selectedOption.value, selectedOption.label);
     console.log(`Option selected:`, selectedOption);
  }

  render() {
     if (this.state.yhdistykset.length === 0) return (null);
      const options = [];

      this.state.yhdistykset.forEach(data => {
          const value =  data.id;
          const label = data.pitkaNimi;
          options.push({
              value: value,
              label: label,
          });          
      });
      const selectedOption = this.state.selectedOption;
      const yhdistysListaus = (
      <div className="ValintaWrapperi">
        <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={options}
        placeholder='Valitse yhdistys...'
        />
      </div>
      );
    return (
      <div>
        {yhdistysListaus}
      </div>
    );
  }
}

Yhdistykset.propTypes = {
    piiriId: PropTypes.number,
    yhdistysOverride: PropTypes.func,
    yhdistysOverrideKaytossa: PropTypes.bool
};

export default Yhdistykset;
