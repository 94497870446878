import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import axios from 'axios';
import { apiBaseUrl, authString } from '../../../../urlPaths';
//import ToimintaryhmaRivi from '../Toimintaryhma/ToimintaryhmaLomake/ToimintaryhmaRivi';
import ToimintaryhmaRiviReduced from '../Toimintaryhma/ToimintaryhmaLomake/ToimintaryhmaRiviReduced';

import Progressbar from '../../../../hoc/FormComponents/Progressbar';
import AlertMessage from '../../../../hoc/FormComponents/AlertMessage';

class Toimintaryhmatlista extends Component {
  state = {
    loading: false,
    error: false,
    toimintaryhmanimet: [],
    maara: null,
    errorMessage: null
  };

  componentDidMount() {
    if (this.state.toimintaryhmanimet.length === 0) {
      this.haeToimintaRyhmaNimet();
      this.setState({ loading: true });
    }
  }

  refreshToimintaryhmanimet = () => {
    this.haeToimintaRyhmaNimet();
    this.setState({ loading: true });
  };

  haeToimintaRyhmaNimet = () => {
    axios
      .get(`${apiBaseUrl}HaeToimintaRyhmaReducedLista`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authString(),
          organisaatio: this.props.organisaatioid,
          toimintamuotoid: this.props.toimintamuotoid, 
        },
        crossdomain: true
      })
      .then(response => {
        this.setState({
          toimintaryhmanimet: response.data,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          error: true,
          loading: false,
          errorMessage: error.message
        });
      });
  };

  render() {
    if (
      this.state.loading === true &&
      this.state.toimintaryhmanimet.length < 1
    ) {
      return null;
    }
    let sisalto = null;
    const filteredByID = this.state.toimintaryhmanimet.filter(
      ryhma => ryhma.toimintamuotoid === this.props.toimintamuotoid
    );
    if (this.state.error) {
      sisalto = (
        <AlertMessage
          alertStyle='danger'
          message={
            'Haussa tapahtui virhe. Käynnistä sovellus uudelleen MLL logosta.'
          }
          details={this.state.errorMessage}
        />
      );
    } else if (this.state.loading) {
      sisalto = (
        <Panel>
          <h4>Haetaan...</h4>
          <Progressbar />
        </Panel>
      );
    } else if (filteredByID.length === 0) {
      sisalto = (
        <Panel>
          <h4>Ei lisättyjä ryhmiä.</h4>
        </Panel>
      );
    } else {
      sisalto = filteredByID.map((ryhma, index) => (
        <ToimintaryhmaRiviReduced
          key={`${ryhma.id}${index}`}
          kayttajaid={this.state.kayttajaId}
          organisaatioid={this.props.organisaatioid}
          goTo={this.props.goTo}
          lomakeauki={this.state.lomakeauki}
          toimintaryhmanimi={ryhma.nimi}
          toimintaryhmaid={ryhma.id}
          pvmalku={ryhma.pvmalku}
          pvmloppu={ryhma.pvmloppu}
          missatoimintaaon={ryhma.missatoimintaaon}
          tilanne={ryhma.tilanne}
          saanayttaa={ryhma.saanayttaa}
          lisatietoa={ryhma.lisatietoa}
          pvm={ryhma.pvm}
          handleTapahtumaTasoAndGoTo={this.props.handleTapahtumaTasoAndGoTo}
          handleToimintaryhmaIdAndNimi={this.props.handleToimintaryhmaIdAndNimi}
          refreshToimintaryhmanimet={this.refreshToimintaryhmanimet}
        />
      ));
    }
    return (
      <div>
        <h3>Ryhmät ja toiminnot</h3>
        {sisalto}
      </div>
    );
  }
}

Toimintaryhmatlista.propTypes = {
  organisaatioid: PropTypes.number,
  goTo: PropTypes.func.isRequired,
  toimintamuotoid: PropTypes.number
};

export default Toimintaryhmatlista;
