import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../../../../styles/tilastolomake.css';
import Wrapper from '../../../../../hoc/wrapper/Wrapper';
import CommonLabeledNumberPicker from '../../../../../hoc/FormComponents/Common/CommonLabeledNumberPicker';
import LabeledDecimalPicker from '../../../../../hoc/FormComponents/LabeledDecimalPicker';

class TilastoLomakeEditori extends Component {
  render() {
    return (
      <Wrapper>
        <div className='LomakeWrapperi'>
          <div className='TilastoWrapperi'>
            <CommonLabeledNumberPicker
              onChange={this.props.handleInputChange}
              name='vapaaehtoisia'
              title='Vapaaehtoisia'
              value={this.props.vapaaehtoisia}
            />
          </div>
          <div className='TilastoWrapperi'>
            <CommonLabeledNumberPicker
              onChange={this.props.handleInputChange}
              name='aikuisia'
              title='Muita aikuisia'
              value={this.props.aikuisia}
            />
          </div>
          <div className='TilastoWrapperi'>
            <CommonLabeledNumberPicker
              onChange={this.props.handleInputChange}
              name='lapsia'
              title='Lapsia'
              value={this.props.lapsia}
            />
          </div>
         
          <LabeledDecimalPicker
            onChange={this.props.handleInputChange}
            name='tyoaikaa'
            title='Työaikaa'
            value={this.props.tyoaikaa}
          />
        </div>
      </Wrapper>
    );
  }
}

TilastoLomakeEditori.propTypes = {
  handleInputChange: PropTypes.func.isRequired
};

export default TilastoLomakeEditori;
/*
  <LabeledDecimalPicker
            onChange={this.props.handleInputChange}
            name='vapehttuntejaperkerta'
            title='Vapaaehtoisten tunteja'
            value={this.props.vapEhtTunteja}
          />
 */