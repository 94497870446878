import React, { Component } from 'react';
import { FormControl, Button, Row, Col, Glyphicon, Checkbox } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { apiBaseUrl, authString } from '../../../../../urlPaths';
import Wrapper from '../../../../../hoc/wrapper/Wrapper';
import '../../../../../styles/yhdistys.css';
import axios from 'axios';
import LabeledInput from '../../../../../hoc/FormComponents/LabeledInput';
// import LabeledNumberPicker from '../../../../../hoc/FormComponents/LabeledNumberPicker';
import CommonLabeledNumberPicker from '../../../../../hoc/FormComponents/Common/CommonLabeledNumberPicker';
import LabeledDecimalPicker from '../../../../../hoc/FormComponents/LabeledDecimalPicker';
import Modal from '../../../../../hoc/FormComponents/Modal';
import Tooltip from '../../../../../hoc/FormComponents/Tooltip';

class ToimintaryhmaInsertLomake extends Component {
  state = {
    Muokkaa: false,
    toimintaryhma: null,
    toimintamuotoid: this.props.toimintamuotoid,
    loading: false,
    error: false,
    errorMessage: null,
    showModal: false,
    success: false
  };

  componentDidMount() {
    console.log('TRINSERTLOMAKE');
    if (this.props.toimintaryhmaid !== null) {
      this.uusiToimintaRyhma();
      this.setState({ loading: true });
    }
  }

  handleInputChange = (updateobjectname, value) => {
    const toim_ryhma = { ...this.state.toimintaryhma };
    toim_ryhma[updateobjectname] = value;
    this.setState({ toimintaryhma: toim_ryhma });
  };

  handleTextChange = e => {
    const target = e.target;
    const kentta = target.attributes.name.nodeValue;
    const toimintaryhma = this.state.toimintaryhma;
    toimintaryhma[kentta] = target.value;
    this.setState({ toimintaryhma });
    };


  handleBoxClick = event => {
      const toim_ryhma = { ...this.state.toimintaryhma };
      if (toim_ryhma.saanayttaa == 1) {
          toim_ryhma.saanayttaa = 0;
      } else {
          toim_ryhma.saanayttaa = 1;
      }
     this.setState({ toimintaryhma: toim_ryhma });
  };


  tallenna = e => {
    e.preventDefault();
    if (
      this.state.toimintaryhma.nimi !== '' &&
      this.state.toimintaryhma.pvmalku
    ) {
      const data = this.state.toimintaryhma;
      data.kayttajaid = this.props.kayttajaId;
      axios
        .post(`${apiBaseUrl}TallennaToimintaRyhmaReduced`, data, {
          headers: {
            Authorization: authString()
          }
        })
        .then(response => {
          const toimintaryhmadata = response.data;
          this.setState({
            toimintaryhma: toimintaryhmadata,
            success: true
          });
          this.showModal();
        })
        .catch(error => {
          this.setState({
            error: true,
            errorMessage: error.message
          });
        });
    } else {
      this.setState({
        error: true,
        errorMessage: 'Täytä vähintään nimi ja aloituspäivämäärä'
      });
      this.showModal();
    }
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  handleHide = () => {
    if (this.state.error) {
      this.setState({
        showModal: false,
        error: false,
        errorMessage: null,
        success: false
      });
    } else {
      this.setState({ showModal: false }, () => {
        this.props.goTo('Toimintamuoto');
      });
    }
  };

  uusiToimintaRyhma = e => {
    const toimintaryhmadata = {
      //aikuisiaperkerta: 0,
      //aikuisiavuodessa: 0,
      id: 0,
      kayttajaid: this.props.kayttajaId,
      nimi: '',
      missatoimintaaon: '',
      saanayttaa: 1,
      //palkallisia: 0,
      pvm: '',
      pvmalku: '',
      pvmloppu: '',
      //kokoontumisiavuodessa: 0,
      //lapsiavuodessa: 0,
      lisatietoa: '',
      //lapsiaperkerta: 0,
      tilanne: '',
      toimintamuotoid: this.state.toimintamuotoid,
      //vapaaehtoisia: 0,
      //vapehttuntejaperkerta: 0
    };
    this.setState({ toimintaryhma: toimintaryhmadata });
  };

  handleClickHeader = event => {
    this.setState({
      Muokkaa: false
    });
  };

  render() {
    let modalContent;
    if (this.state.toimintaryhma === null) {
      return null;
    }

    if (this.state.showModal && this.state.error) {
      modalContent = (
        <Modal
          message={'Tallennus epäonnistui'}
          details={this.state.errorMessage}
          messageStyle={'danger'}
          title={'Toimintaryhmän lisäys'}
          show={this.state.showModal}
          onHide={this.handleHide}
          buttonStyle={'primary'}
          buttonText={'Sulje'}
          withCancel={false}
        />
      );
    } else if (this.state.showModal && this.state.success) {
      modalContent = (
        <Modal
          message={'Tallennus onnistui'}
          messageStyle={'success'}
          title={'Toimintaryhmän lisäys'}
          show={this.state.showModal}
          onHide={this.handleHide}
          buttonStyle={'primary'}
          buttonText={'Palaa etusivulle'}
        />
      );
    }
    console.log('RYHMA');
    console.log(this.state.toimintaryhma);

    let checked = false;
    if (this.state.toimintaryhma.saanayttaa == 1) {
       checked = true;
    } else
    {
       checked = false;
    }

    return (
      <Wrapper>
        {this.state.showModal ? modalContent : null}
        <Row className='ToimintamuotoOtsikko'>
          <Col className='col-xs-2'>
            <Button onClick={() => this.props.goTo('Toimintamuoto')}>
              <Glyphicon glyph='arrow-left' />
            </Button>
          </Col>
          <Col className='col-xs-8'>
            <h3>{this.props.otsikko}</h3>
          </Col>
          <Col className='col-xs-2'>
            <Tooltip
              placement='left'
              title='Uuden toimintaryhmän lisääminen'
              ohjetekstinimi='tryhmanlisays'
            />
          </Col>
        </Row>
        <form onSubmit={this.tallenna}>
          <h3 className='text-center'>Lisää ryhmä, toiminto tai tapahtuma</h3>
          <LabeledInput
            title='Nimi'
            name='nimi'
            type='text'
            value={this.state.toimintaryhma.nimi}
            placeholder='Syötä nimi'
            onChange={this.handleTextChange}
            maxLength='50'
          />
          <LabeledInput
            title='Alkupvm.'
            name='pvmalku'
            type='date'
            value={this.state.toimintaryhma.pvmalku}
            placeholder='Aloituspäivämäärä'
            onChange={this.handleTextChange}
            maxLength='10'
          />
          <LabeledInput
            title='Loppupvm (jos on / tiedossa)'
            name='pvmloppu'
            type='date'
            value={this.state.toimintaryhma.pvmloppu}
            placeholder='Lopetuspäivämäärä'
            onChange={this.handleTextChange}
            maxLength='10'
           />
          <LabeledInput
            title='Missä toimintaa on?'
            name='missatoimintaaon'
            type='text'
            value={this.state.toimintaryhma.missatoimintaaon}
            placeholder='Missä?'
            onChange={this.handleTextChange}
            maxLength='250'
          />
          <LabeledInput
            title='Milloin toimintaa on?'
            name='tilanne'
            type='text'
            value={this.state.toimintaryhma.tilanne}
            placeholder='Milloin?'
            onChange={this.handleTextChange}
            maxLength='250'
          />
          <LabeledInput
            title='Mistä saa lisätietoa? (esim. linkki tai sposti)'
            name='lisatietoa'
            type='text'
            value={this.state.toimintaryhma.lisatietoa}
            placeholder='Mistä saa lisätietoa?'
            onChange={this.handleTextChange}
            maxLength='500'
           />
           <Checkbox
              onChange={this.handleBoxClick}
              checked={checked}
           >
            nämä tiedot saa näyttää MLL nettisivuilla
            </Checkbox>
          <FormControl.Feedback />

          <Button bsClass='TallennaRyhma' type='submit'>
            Tallenna ryhmä
          </Button>
        </form>
      </Wrapper>
    );
  }
}
//defaultValue={this.state.toimintaryhma.aikuisiavuodessa}
//defaultValue={this.state.toimintaryhma.aikuisiaperkerta}
//defaultValue={this.state.toimintaryhma.lapsiavuodessa}
//defaultValue={this.state.toimintaryhma.lapsiaperkerta}

ToimintaryhmaInsertLomake.propTypes = {
    toimintamuotoid: PropTypes.number,
    kayttajaId: PropTypes.number
};
export default ToimintaryhmaInsertLomake;


/* POISTETTU TILASTOINTIKENTÄT 3.6.2019 / Tiketti #89
 
 <CommonLabeledNumberPicker
            title='Kokoontumisia / v'
            name='kokoontumisiavuodessa'
            placeholder='kokoontumisiavuodessa'
            value={this.state.toimintaryhma.kokoontumisiavuodessa}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Aikuisia / v'
            name='aikuisiavuodessa'
            placeholder='aikuisiavuodessa'
            value={this.state.toimintaryhma.aikuisiavuodessa}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Aikuisia / kerta'
            name='aikuisiaperkerta'
            placeholder='aikuisiaperkerta'
            value={this.state.toimintaryhma.aikuisiaperkerta}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Lapsia / v'
            name='lapsiavuodessa'
            placeholder='lapsiavuodessa'
            value={this.state.toimintaryhma.lapsiavuodessa}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Lapsia / kerta'
            name='lapsiaperkerta'
            placeholder='lapsiaperkerta'
            value={this.state.toimintaryhma.lapsiaperkerta}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Vapaaehtoisten lkm'
            name='vapaaehtoisia'
            placeholder='vapaaehtoisia'
            value={this.state.toimintaryhma.vapaaehtoisia}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Palkallisten lkm'
            name='palkallisia'
            placeholder='palkallisia'
            value={this.state.toimintaryhma.palkallisia}
            onChange={this.handleInputChange}
          />
          <LabeledDecimalPicker
            title='Vapaaehtoisten tunnit / kerta'
            name='vapehttuntejaperkerta'
            placeholder='vapaaehtoisten tunnit keskimäärin'
            value={this.state.toimintaryhma.vapehttuntejaperkerta}
            onChange={this.handleInputChange}
          />
 * */