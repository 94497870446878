import React, { Component } from 'react';
import { Button, Row, Col, Collapse } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { apiBaseUrl, authString } from '../../../../../urlPaths';
import axios from 'axios';
import Wrapper from '../../../../../hoc/wrapper/Wrapper';
import '../../../../../styles/yhdistys.css';
import Modal from '../../../../../hoc/FormComponents/Modal';
import moment from 'moment';

const formatDate = date => moment(date).format('D.M.YYYY');
class ToimintaryhmaRiviReduced extends Component {
  state = {
    toimintaryhma: null,
    error: false,
    errorMessage: null,
    success: false,
    showModal: false,
    loading: false,
    pvmLoppu: '',
    viewDetails: false
  };

  handlePvmChange = e => {
    this.setState({ pvmloppu: e.target.value });
  };

  handleTapahtumaClick = () => {
    this.props.handleToimintaryhmaIdAndNimi(
      this.props.toimintaryhmaid,
      this.props.toimintaryhmanimi
    );
    this.props.handleTapahtumaTasoAndGoTo('Toimintaryhma', 'Tapahtuma');
  };

  handleRefreshToimintaryhmalista = () => {
    this.props.refreshToimintaryhmanimet();
  };

  handleMuokkaaClick = () => {
    this.props.handleToimintaryhmaIdAndNimi(
      this.props.toimintaryhmaid,
      this.props.toimintaryhmanimi
    );
    this.props.goTo('ToimintaryhmaLomake');
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleRemove = () => {
    this.poistaToimintaryhma();
    this.setState({
      showModal: false
    });
  };

  poistaToimintaryhma = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: authString(),
      toimintaryhmaid: this.props.toimintaryhmaid,
      pvmloppu: this.state.pvmloppu,
      kayttajaid: this.props.kayttajaid
    };
    axios
      .post(`${apiBaseUrl}PoistaToimintaRyhmaKaytosta`, null, {
        headers
      })
      .then(response => {
        const responseData = response.data;
        this.handleRefreshToimintaryhmalista();
        console.log(responseData);
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleRowClick = () => {
    if (!this.state.viewDetails) {
      this.setState({ viewDetails: true });
    } else {
      this.setState({ viewDetails: false });
    }
  };

  render() {
    const modal = (
      <Modal
        message={`Olet lopettamassa toimintaryhmän ${
        this.props.toimintaryhmanimi
        }. Syötä ajankohta milloin toiminta loppui ja valitse lopeta. 
        Jos toiminnalle on syötetty tietoja, ne säilyvät tallessa mutta et pääse enää muokkaamaan niitä`}
        messageStyle={'danger'}
        title={'Toimintaryhmän lopetus'}
        show={this.state.showModal}
        onHide={this.handleRemove}
        buttonStyle={'danger'}
        buttonText={'Lopeta'}
        withCancel={true}
        onCancel={this.handleCancel}
        askEndDate={true}
        onChange={this.handlePvmChange}
        name={'pvmloppu'}
        value={this.state.pvmloppu}
      />
    );
    return (
      <Wrapper>
        {this.state.showModal ? modal : null}
        <Row
          className='Toimintaryhmakokonaisuus'
          style={{ height: '40%', cursor: 'pointer' }}
        >
          <h3>
            {this.props.toimintaryhmanimi} 
          </h3>
        </Row>

        <Row style={{ margin: '2%' }}>
          <Col className='col-xs-4'>
            <Button bsClass='MuokkaaRyhma' onClick={this.handleMuokkaaClick}>
              Muokkaa{' '}
            </Button>
          </Col>
          <Col className='col-xs-4'>
            <Button bsClass='MuokkaaRyhma' onClick={this.handleTapahtumaClick}>
              Tilastot
            </Button>
          </Col>
          <Col className='col-xs-4'>
            <Button bsClass='PoistaRyhma' onClick={this.showModal}>
              Lopeta
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className='col-xs-12'>
            <Button bsClass='MuokkaaRyhma' onClick={this.handleRowClick}>
              {this.state.viewDetails ? 'Piilota tiedot' : 'Katso tiedot'}
            </Button>
          </Col>
        </Row>
        <Collapse in={this.state.viewDetails}>
          <div>
            <Row>
              <Col className='col-xs-12'>
                <h3>Nimi</h3>
              </Col>
            </Row>
            <Row>
              <Col className='col-xs-12'>
                <p>{this.props.toimintaryhmanimi}</p>
              </Col>
            </Row>
            <Row>
              <h3>Alkupvm</h3>
              <p>{formatDate(this.props.pvmalku)}</p>
             </Row>
            <Row>
               <h3>Loppupvm</h3>
               <p>{formatDate(this.props.pvmloppu)}</p>
            </Row>
            <Row>
              <h3>Missä toimintaa on?</h3>
              <p>{this.props.missatoimintaaon}</p>
            </Row>
            <Row>
            <h3>Milloin toimintaa on?</h3>
              <p>{this.props.tilanne}</p>
            </Row>
            <Row>
              <h3>Mistä lisätietoa?</h3>
              <p>{this.props.lisatietoa}</p>
            </Row>
              <Row>
              <h3>Saako näyttää?</h3>
              <p>{this.props.saanayttaa == 1 ? 'Kyllä' : 'Ei'}</p>
              </Row>
            <Row>
              <h3>Kirjaus pvm</h3>
              <p>{formatDate(this.props.pvm)}</p>
            </Row>
            <Button bsClass='MuokkaaRyhma' onClick={this.handleRowClick}>
              Sulje
            </Button>
          </div>
        </Collapse>
        <hr style={{ border: '1px solid black' }} />
      </Wrapper>
    );
  }
}

ToimintaryhmaRiviReduced.propTypes = {
  toimintaryhmaid: PropTypes.number,
  organisaatioid: PropTypes.number,
  kayttajaid: PropTypes.number,
  refreshToimintaryhmanimet: PropTypes.func.isRequired
};

export default ToimintaryhmaRiviReduced;
