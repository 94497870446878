import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

const alertMessage = props => (
    <Alert bsStyle={props.alertStyle}>
      <h4>{props.message}</h4>
      <p>{props.details}</p>
    </Alert>
  );
alertMessage.propTypes = {
  message: PropTypes.string,
  details: PropTypes.string
};

export default alertMessage;
