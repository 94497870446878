import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Glyphicon, Form } from 'react-bootstrap';
import '../../../../styles/yhdistys.css';
import Wrapper from '../../../../hoc/wrapper/Wrapper';
import LabeledInput from '../../../../hoc/FormComponents/LabeledInput';
import CommonLabeledNumberPicker from '../../../../hoc/FormComponents/Common/CommonLabeledNumberPicker';
import LabeledDecimalPicker from '../../../../hoc/FormComponents/LabeledDecimalPicker';
import axios from 'axios';
import { apiBaseUrl, authString } from '../../../../urlPaths';
import Modal from '../../../../hoc/FormComponents/Modal';
import Tooltip from '../../../../hoc/FormComponents/Tooltip';

class ToimintamuotoLomake extends Component {
  state = {
    toimintamuoto: null,
    loading: true,
    error: false,
    errorMessage: null,
    showModal: false,
    success: false
  };

  componentDidMount() {
    if (this.props.toimintamuotoId !== null) {
      this.getToimintamuoto();
    }
  }

  handleInputChange = (updateobjectname, value) => {
    const toimintamuotoupdate = { ...this.state.toimintamuoto };
    toimintamuotoupdate[updateobjectname] = value;
    this.setState({ toimintamuoto: toimintamuotoupdate });
  };

  handleTextChange = e => {
    const target = e.target;
    const kentta = target.attributes.name.nodeValue;
    const toimintamuoto = this.state.toimintamuoto;
    toimintamuoto[kentta] = target.value;
    this.setState({ toimintamuoto });
  };

  muokkaaToimintamuoto = e => {
    e.preventDefault();
    const toimintamuotoObjekti = this.state.toimintamuoto;
    const headers = {
      Authorization: authString(),
      kayttajaId: this.props.kayttajaId,
      organisaatioId: this.props.organisaatioId
    };

    axios
      .post(`${apiBaseUrl}TallennaToimintaMuoto`, toimintamuotoObjekti, {
        headers
      })

      .then(response => {
        const newToimintamuotoData = response.data;
        console.log(newToimintamuotoData);
        this.setState({
          toimintamuoto: newToimintamuotoData,
          showModal: true,
          success: true
        });
      })
      .catch(error => {
        this.setState({
          error: true,
          errorMessage: error.message,
          showModal: true
        });
      });
    this.showModal();
  };

  getToimintamuoto = () => {
    axios
      .get(`${apiBaseUrl}HaeToimintamuoto`, {
        headers: {
          Authorization: authString(),
          id: this.props.toimintamuotoId
        },
        crossdomain: true
      })
      .then(response => {
        // If request is good...
        const toimintamuotoData = response.data[0];
        this.setState({ toimintamuoto: toimintamuotoData, loading: false });
      })
      .catch(error => {
        this.setState({
          error: true,
          errorMessage: error.message
        });
      });
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  handleHide = () => {
    if (this.state.error) {
      this.setState({
        showModal: false,
        error: false,
        errorMessage: null,
        success: false
      });
    } else {
      this.setState({ showModal: false }, () => {
        this.props.goTo('Yhdistys');
      });
    }
  };

  render() {
    let modalContent;
    if (this.state.loading) {
      return null;
    }
    if (this.state.showModal && this.state.error) {
      modalContent = (
        <Modal
          message={'Tallennus epäonnistui'}
          details={this.state.errorMessage}
          messageStyle={'danger'}
          title={'Toimintamuodon muokkaus'}
          show={this.state.showModal}
          onHide={this.handleHide}
          buttonStyle={'primary'}
          buttonText={'Sulje'}
          withCancel={false}
        />
      );
    } else if (this.state.showModal && this.state.success) {
      modalContent = (
        <Modal
          message={'Tallennus onnistui'}
          messageStyle={'success'}
          title={'Toimintamuodon muokkaus'}
          show={this.state.showModal}
          onHide={this.handleHide}
          buttonStyle={'primary'}
          buttonText={'Palaa etusivulle'}
        />
      );
    }

    return (
      <Wrapper>
        {this.state.showModal ? modalContent : null}
        <Row className='ToimintamuotoOtsikko'>
          <Col className='col-xs-2'>
            <Button onClick={() => this.props.goTo('Yhdistys')}>
              <Glyphicon glyph='arrow-left' />
            </Button>
          </Col>
          <Col className='col-xs-8'>
            <h3>{this.props.otsikko}</h3>
          </Col>
          <Col className='col-xs-2'>
            <Tooltip
              placement='left'
              title='Toimintamuodon muokkaus'
              ohjetekstinimi='tmuodonmuokkaus'
            />
          </Col>
        </Row>
        <Form onSubmit={this.muokkaaToimintamuoto}>
          <h3 className='text-center'>Muokkaa toimintamuodon tietoja</h3>
          <LabeledInput
            title='Aloituspäivämäärä'
            name='pvm_alku'
            type='date'
            value={this.state.toimintamuoto.pvm_alku}
            placeholder='Aloituspäivämäärä'
            onChange={this.handleTextChange}
            maxLength='10'
           />
          <Button bsClass='TallennaRyhma' type='submit'>
            Tallenna
          </Button>
        </Form>
      </Wrapper>
    );
  }
}
ToimintamuotoLomake.propTypes = {
  organisaatioId: PropTypes.number,
  toimintamuotoId: PropTypes.number,
  kayttajaId: PropTypes.number
};

export default ToimintamuotoLomake;

/* Kenttäjemma. 
  <CommonLabeledNumberPicker
            title='Toimintaryhmien lkm'
            name='toimintaryhmienlukumaara'
            placeholder='Toimintaryhmien lkm'
            value={this.state.toimintamuoto.toimintaryhmienlukumaara}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Kokoontumisia / v'
            name='kokoontumisiavuodessa'
            placeholder='Kokoontumisia / v'
            value={this.state.toimintamuoto.kokoontumisiavuodessa}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Aikuisia / v'
            name='aikuisiavuodessa'
            placeholder='Aikuisia / v'
            value={this.state.toimintamuoto.aikuisiavuodessa}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Lapsia / v'
            name='lapsiavuodessa'
            placeholder='Lapsia'
            value={this.state.toimintamuoto.lapsiavuodessa}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Vapaaehtoisten lukumäärä'
            name='vapaaehtoisia'
            placeholder='Vapaaehtoisia'
            value={this.state.toimintamuoto.vapaaehtoisia}
            onChange={this.handleInputChange}
          />
          <CommonLabeledNumberPicker
            title='Palkallisia'
            name='palkallisia'
            placeholder='Aikuisia'
            value={this.state.toimintamuoto.palkallisia}
            onChange={this.handleInputChange}
          />
          <LabeledDecimalPicker
            title='Vapaaehtoisten tunnit / kerta'
            name='vapaaehtoistentuntejaperkokoontumiskertakeskimaarin'
            placeholder='Vapaaehtoisten tunnit keskimäärin'
            value={
              this.state.toimintamuoto
                .vapaaehtoistentuntejaperkokoontumiskertakeskimaarin
            }
            onChange={this.handleInputChange}
          />
          <LabeledInput
            title='Tilanne'
            name='tilanne'
            type='text'
            placeholder='Tilanne'
            value={this.state.toimintamuoto.tilanne}
            onChange={this.handleTextChange}
          />
          <LabeledInput
            title='Lisätietoa'
            name='lisatietoa'
            type='text'
            value={this.state.toimintamuoto.lisatietoa}
            placeholder='Lisätietoa'
            onChange={this.handleTextChange}
          />
*/

