import React from 'react';
import { Navbar } from 'react-bootstrap';
import Logo from '../logo/Logo';

const titleBar = props => (
    <div>
      <Navbar fluid>
        <Navbar.Header>
          <Logo />
        </Navbar.Header>
      </Navbar>
    </div>
  );

export default titleBar;
