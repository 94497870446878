import React from 'react';
import PropTypes from 'prop-types';
import { Button, Glyphicon, Row, Col } from 'react-bootstrap';
import '../../../../styles/yhdistys.css';
import Wrapper from '../../../../hoc/wrapper/Wrapper';
import Toimintamuotoinfo from '../../../../hoc/FormComponents/Toimintamuotoinfo';

const ToimintamuotoOtsikko = props => {
  const toggleVari = value => {
    switch (value) {
      case null:
        return 'EiToimintaValintaa';
      case 0:
        return 'EiToimintaa';
      case 1:
        return 'OnToimintaa';
      default:
        return 'EitoimintaValintaa';
    }
  };

  const handleDataAndOpenModal = () => {
    props.handleData(
      props.data.id,
      props.data.toimintamuoto_Id,
      props.data.otsikko
    );
    props.showModal(props.data.id);
  };

  return (
    <div className='ToimintamuotoOtsikko' key={props.data.id}>
      <Row style={{ height: '75px' }}>
        <Col className='col-xs-1'>
           {props.modify && props.data.onkoToimintaa !== 1 ? (
                      <div
                          onClick={() =>
                              props.handleDataAndGoTo(
                                  props.data.id,
                                  props.data.toimintamuoto_Id,
                                  props.data.otsikko,
                                  'ToimintamuodonAloitus'
                              )
                          }
                      >
                          <Button bsStyle='success' id={props.data.id} name='Kylla'>
                              <Glyphicon glyph='ok-sign' />
                          </Button>
                      </div>
                  ) : null
           }
           {props.modify && props.data.onkoToimintaa !== null ? (
                  <Button
                   onClick={handleDataAndOpenModal}
                   id={props.data.id}
                   bsStyle='danger'
                   name='Ei'
                  >
                  <Glyphicon glyph='remove-sign' />
                  </Button>
          ) : null
          }
        </Col>
        <Col className='col-xs-1'>
                  <Toimintamuotoinfo
                   placement='right'
                   location='right'
                   title={props.data.otsikko}
                   content='Ei t�ytetty'
                   ohjeteksti={props.data.ohjeteksti}
         />
        </Col>
        <div
          onClick={() =>
            props.modify === false &&
            props.data.onkoToimintaa !== 0 &&
            props.data.onkoToimintaa !== null &&
            props.data.onkoToimintaa === 1
              ? props.handleDataAndGoTo(
                  props.data.id,
                  props.data.toimintamuoto_Id,
                  props.data.otsikko,
                  'Toimintamuoto'
                )
              : null
          }
        >
        <Col className='col-xs-8'>
          <h5 className={toggleVari(props.data.onkoToimintaa)}>
             {props.data.otsikko}
          </h5>
        </Col>
        </div>
        {props.modify &&
        props.data.onkoToimintaa !== 0 &&
        props.data.onkoToimintaa !== null ? (
          <Wrapper>
            <Col className='col-xs-1'>
              <Button
                onClick={() =>
                  props.handleDataAndGoTo(
                    props.data.id,
                    props.data.toimintamuoto_Id,
                    props.data.otsikko,
                    'ToimintamuotoLomake'
                  )
                }
                bsStyle='warning'
                name='Muokkaa'
              >
                <Glyphicon glyph='cog' />
              </Button>
            </Col>
          </Wrapper>
        ) : (
          <div
            onClick={() =>
              props.handleDataAndGoTo(
                props.data.id,
                props.data.toimintamuoto_Id,
                props.data.otsikko,
                'Toimintamuoto'
              )
            }
          >
            <Col className='col-xs-1'>
              {props.data.onkoToimintaa === 1 ? (
                <Button bsClass='LinkkiNappiNuoli'>
                  <Glyphicon glyph='circle-arrow-right' />
                </Button>
              ) : null}
            </Col>
          </div>
        )}
      </Row>
    </div>
  );
};

export default ToimintamuotoOtsikko;

ToimintamuotoOtsikko.propTypes = {
  modify: PropTypes.bool.isRequired,
  data: PropTypes.object,
  handleDataAndGoTo: PropTypes.func.isRequired
};
