import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button, Glyphicon, Collapse } from 'react-bootstrap';
import axios from 'axios';
import { apiBaseUrl, authString } from '../../../../../../urlPaths';
import '../../../../../../styles/yhdistys.css';
import '../../../../../../styles/linkkinappi.css';
import Modal from '../../../../../../hoc/FormComponents/Modal';
import moment from 'moment';
import Wrapper from '../../../../../../hoc/wrapper/Wrapper';

const formatDate = date => moment(date).format('D.M.YYYY');

class TapahtumaListaRivi extends Component {
  state = {
      viewDetails: false,
      deleting: false,
      showModal: false,
      poistettavaId: null,
  };

  toggleGoTo = taso => {
    switch (taso) {
      case 'Organisaatio':
        this.props.handleTapahtumaId(this.props.tapahtuma.id);
        this.props.handleTapahtumaTasoAndGoTo(taso, 'TapahtumanMuokkaus');
        break;
      case 'Toimintamuoto':
        this.props.handleTapahtumaId(this.props.tapahtuma.id);
        this.props.handleTapahtumaTasoAndGoTo(taso, 'TapahtumanMuokkaus');
        break;
      case 'Toimintaryhma':
        this.props.handleTapahtumaId(this.props.tapahtuma.id);
        this.props.handleTapahtumaTasoAndGoTo(taso, 'TapahtumanMuokkaus');
        break;
      default:
        return null;
    }
  };
  
  showModaali = (id) => {
      this.setState({ showModal: true, poistettavaId: id  });
  }

  onDelete = () => {
      this.poistaTapahtuma();
      this.props.goTo('Toimintamuoto');
      this.setState({ showModal: false });
  }
  onCancel = () => {
      this.setState({ showModal: false });
  }

  poistaTapahtuma =() => {
        const headers = {
            Authorization: authString(),
            id: this.state.poistettavaId,
        };
        axios
            .get(`${apiBaseUrl}PoistaTapahtuma`,{ headers })
            .then(response => {
            //  const vastaus = response.data;
            })
            .catch(error => {
            });
    };

  handleRowClick = () => {
    if (!this.state.viewDetails && !this.props.modify) {
      this.setState({ viewDetails: true });
    } else {
      this.setState({ viewDetails: false });
    }
  };

    render() {
    if (this.state.deleting === true) return (null);
        if (this.state.showModal === true) {
            return (
                <Modal
                    message={'Haluatko varmasti poistaa!'}
                    details={this.state.errorMessage}
                    messageStyle={'danger'}
                    title={'Poisto'}
                    show={this.state.showModal}
                    onHide={this.onDelete}
                    onCancel={this.onCancel}
                    buttonStyle={'primary'}
                    buttonText={'Poista'}
                    withCancel={true}
                />
            );
        }
    return (
      <Wrapper>
        <Row className='TapahtumaRivi' onClick={this.handleRowClick}>
          <Row>
            <Col className='col-xs-10'>
              <Row style={{ color: 'white', fontSize: '15px' }}>
                <Col className='col-xs-12'>
                <h4>{this.props.tapahtuma.otsikko}</h4>
                </Col>
              </Row>
              <Row style={{ color: 'white', fontSize: '15px' }}>
                <Col className='col-xs-12'>
                <p>Aikaväli: {formatDate(this.props.tapahtuma.alkupvm)} - {formatDate(this.props.tapahtuma.loppupvm)} </p>
                </Col>
              </Row>
              <Row style={{ color: 'white', fontSize: '15px' }}>
                 <Col className='col-xs-12'>
                    <p>{this.props.tapahtuma.kirjaaja}</p>
                 </Col>
              </Row>
            </Col>
            <Col className='col-xs-2'>
              <Row>
                {this.props.modify ? (
                  <Col className='col-xs-12'>
                    <Button
                      onClick={() => this.toggleGoTo(this.props.tapahtumaTaso)}
                      bsStyle='warning'
                      name='Muokkaa'
                    >
                      <Glyphicon glyph='cog' />
                    </Button>
                    <Button
                        onClick={()=>this.showModaali(this.props.tapahtuma.id)}
                        bsStyle='danger'
                        name='Poista'
                       >
                    <Glyphicon glyph='remove' />
                    </Button>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
        </Row>
        <Collapse in={this.state.viewDetails}>
          <div>
            <Row>
              <h3>Otsikko</h3>
              <p>{this.props.tapahtuma.otsikko}</p>
            </Row>
            <Row>
              <h3>Aikaväli</h3>
              <p>{formatDate(this.props.tapahtuma.alkupvm)} - {formatDate(this.props.tapahtuma.loppupvm)}</p>
            </Row>
            <Row>
              <h3>Toimintaryhmien määrä</h3>
              <p>{this.props.tapahtuma.toimintaryhmia}</p>
            </Row>
            <Row>
              <h3>Kokoontumisten/tapahtumien määrä</h3>
              <p>{this.props.tapahtuma.tapahtumia}</p>
            </Row>
            <Row>
              <h3>Aikuisten käyntikertoja</h3>
              <p>{this.props.tapahtuma.aikuisia}</p>
            </Row>
            <Row>
              <h3>Lasten käyntikertoja</h3>
              <p>{this.props.tapahtuma.lapsia}</p>
            </Row>
            <Row>
              <h3>Vapaaehtoisten tunnit yhteensä</h3>
             <p>{this.props.tapahtuma.vapehttuntejayht}</p>
            </Row>
            <Row>
            <h3>Vapaaehtoisia per kerta keskimäärin</h3>
               <p>{this.props.tapahtuma.vapehtperkerta}</p>
            </Row>
            <Row>
              <h5>Kirjaamis Pvm</h5>
              <p>{formatDate(this.props.tapahtuma.pvm)}</p>
            </Row>
            <Button bsClass='LinkkiNappi' onClick={this.handleRowClick}>
              Sulje
            </Button>
          </div>
        </Collapse>
      </Wrapper>
    );
  }
}

TapahtumaListaRivi.propTypes = {
    goTo: PropTypes.func.isRequired,
    orgId: PropTypes.any
};

export default TapahtumaListaRivi;
