import React, { Component } from 'react';
import { ControlLabel, Button, Col, Row } from 'react-bootstrap';
import '../../styles/tilastolomake.css';
import Tooltip from '../FormComponents/Tooltip';

const rowStyle = {
  width: '250px'
};

const fieldStyle = {
  width: '100px'
};

class LabeledDecimalPicker extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.addHourHandler = this.addHourHandler.bind(this);
    this.subtractHourHandler = this.subtractHourHandler.bind(this);
    this.addMinutesHandler = this.addMinutesHandler.bind(this);
    this.subtractMinutesHandler = this.subtractMinutesHandler.bind(this);
  }

  addHourHandler = event => {
    const newvalue = parseFloat(this.props.value, 10) + 1;
    this.props.onChange(event.target.name, newvalue);
  };

  subtractHourHandler = event => {
      let newvalue = this.props.value > 0 ? parseFloat(this.props.value, 10) - 1 : 0;
      if (newvalue < 0) {
          newvalue = 0;
      }
      this.props.onChange(event.target.name, newvalue);
  };

  addMinutesHandler = event => {
    const newvalue = parseFloat(this.props.value, 10) + 0.25;
    this.props.onChange(event.target.name, newvalue);
  };

  subtractMinutesHandler = event => {
    let newvalue = this.props.value > 0 ? parseFloat(this.props.value, 10) - 0.25 : 0;
    if (newvalue < 0) {
          newvalue = 0;
    }
    this.props.onChange(event.target.name, newvalue);
  };

  handleChange = event => {
    this.props.onChange(event.target.name, parseFloat(event.target.value, 10));
  };

  render() {
  let tip = null;

        if (typeof(this.props.tooltip) != 'undefined') {
            tip = <Col className='col-xs-1'>
                <Tooltip
                    placement='right'
                    location='right'
                    ohjetekstinimi={this.props.tooltip}
                    title={this.props.title}
                    content='t�h�n pit�isi lis�t� infoteksti� :)'
                />
            </Col>
        }
        else {
            tip = <Col className='col-xs-1' />
        }

    return (
      <Row>
         <ControlLabel>{this.props.title}</ControlLabel>
         <p>{this.props.tarkennukset}</p>
         <Row bsStyle={rowStyle}>
             <Col xs={6} md={6}>
                 <Button
                    bsStyle='danger'
                    className='PlusMiinusNappi'
                    onClick={this.subtractHourHandler}
                    name={this.props.name}
                 >
                 -
                 </Button>
                   
              </Col>
              <Col xs={6} md={6}>
                 <Button
                    bsStyle='success'
                     className='PlusMiinusNappi'
                     onClick={this.addHourHandler}
                    name={this.props.name}
                 >
                 +
                 </Button>
              </Col>
         </Row>
            <Row>
            <Col className='col-xs-1' ></Col>
            {tip}
            <Col className='col-xs-8' >
            <input
              style={fieldStyle}
              name={this.props.name}
              placeholder={this.props.value}
              className='Numerokentta'
              onChange={this.handleChange}
              value={this.props.value}
                />
            </Col>
            <Col className='col-xs-2' ></Col>
         </Row>
         <Row>
            <Col xs={6} md={6}>
               <Button
                   bsStyle='danger'
                   name={this.props.name}
                    className='PlusMiinusNappi'
                   onClick={this.subtractMinutesHandler}
                >
                -
               </Button>
            </Col>
            <Col xs={6} md={6}>
               <Button
                   bsStyle='success'
                   className='PlusMiinusNappi'
                   onClick={this.addMinutesHandler}
                   name={this.props.name}
               >
               +
               </Button>
             </Col>
         </Row>
      </Row>
    );
  }
}

export default LabeledDecimalPicker;
