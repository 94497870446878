import React, { Component } from 'react';
import { Button, Modal, Panel, FormControl, Row, ControlLabel } from 'react-bootstrap';
import AlertMessage from './AlertMessage';
import moment from 'moment';

const style = value => {
  switch (value) {
    case 'success':
      return 'success';
    case 'danger':
      return 'danger';
    default:
      return 'primary';
  }
};

const withCancelButton = value => {
  if (!value) {
    return false;
  }
  return true;
};

const fieldStyle = {
  margin: '15px auto',
  borderTop: 'solid gray',
  padding: '15px',
  width: '250px',
  textAlign: 'center',
  color: 'black'
};

const handleDate = value => moment(value).format('YYYY-MM-DD');
class CustomModal extends Component {
  render() {
    return (
      <div className='modal-container' style={{ height: 200 }}>
        <Modal
          show={this.props.show}
          container={this}
          onHide={this.props.onHide}
          aria-labelledby='contained-modal-title'
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title'>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Panel />
            <AlertMessage
              message={this.props.message}
              details={this.props.details}
              alertStyle={this.props.messageStyle}
            />
            {this.props.askEndDate ? (
              <Row style={fieldStyle}>
                <ControlLabel>{this.props.title}</ControlLabel>
                <FormControl
                  type='date'
                  placeholder={this.props.value}
                  name={this.props.name}
                  onChange={this.props.onChange}
                  value={handleDate(this.props.value)}
                />
              </Row>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            {withCancelButton(this.props.withCancel) ? (
              <Button bsStyle={'primary'} onClick={this.props.onCancel}>
                Peruuta
              </Button>
            ) : null}
            <Button bsStyle={style(this.props.buttonStyle)} onClick={this.props.onHide}>
              {this.props.buttonText}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default CustomModal;
